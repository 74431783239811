.disabled-link {
  opacity: 0.3 !important;
  pointer-events: none;
}

.navigationControl {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 36px;

  h2 {
    margin: 0;
    .btn-rounded {
      margin-left: 0;
    }
  }

  .topNavLinks {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn {
      @include border-radius(100px);
      padding: 12px 20px;
      height: 40px;
      @include poppins(500);
      font-size: $font14;

      i {
        margin-left: 8px;
        display: flex;
        align-items: center;
      }
    }
  }

  .btn-rounded {
    margin-left: 12px;
  }
}

@include media-breakpoint-down(lg) {
  .navigationControl h2,
  .navigationControl .h2 {
    font-size: 18px;
  }
}

@include media-breakpoint-down(sm) {
  .navigationControl {
    flex-direction: column;
    align-items: flex-start;
  }
  .navigationControl .topNavLinks {
    order: 1;
  }
  .navigationControl h2 {
    order: 2;
    margin-top: 10px;
  }
  .navigationControl .topNavLinks .btn {
    padding: 6px 5px;
    height: 30px;
    font-size: 12px;
  }
  .btn-rounded {
    width: 30px;
    height: 30px;
  }
  .navigationControl h2 .btn-rounded {
    margin-right: 5px !important;
  }
  .navigationControl .btn-rounded {
    margin-left: 6px;
  }
}

@media (max-width: 400px) {
  .navigationControl .topNavLinks .btn {
    font-size: 11px;
  }
}
