@import "./variables.scss";

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  font-size: 50%;
}

.download-faq {
  display: none; // temporary
}

button {
  font-size: 16px !important;

  .copLink-btn {
    font-size: 25px !important;

    .btn_buton {
      font-size: 12px !important;
    }

    span {
      font-size: 12px;
    }
  }
}

.copy-component {
  font-size: 12px;
}

.btn_buton_customize {
  font-size: 12px !important;
}

.liner_border {
  color: $darkest-purple;
  background-color: $darkest-purple;
}

.download_btn {
  .copy_component {
    font-size: 12px !important;
  }
}

.download_btn {
  span {
    font-size: 12px !important;
  }
}

.btn-wrapper {
  .btn_buton {
    font-size: 12px !important;
  }
}

.download_btn_component {
  font-size: 12px !important;
  &:hover {
    color: $light-purple !important;
    background-color: black !important;
    background: black !important;
  }
}

@mixin phone {
  @media (max-width: 767.8px) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: 1023.8px) {
    @content;
  }
}
@mixin tab {
  @media (min-width: 768px) and (max-width: 1023.8px) {
    @content;
  }
}
@mixin not {
  @media (min-width: 1024px) and (max-width: 1379.8px) {
    @content;
  }
}
@mixin ipad-min {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin not-min {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin not-max {
  @media (max-width: 1660px) {
    @content;
  }
}
@mixin small {
  @media (max-width: 500px) {
    @content;
  }
}
@mixin lineClamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: #{$line};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin flex($align, $justify) {
  display: flex;
  align-items: #{$align};
  justify-content: #{$justify};
}

@mixin container {
  width: 100%;
  max-width: 1625px;
  padding: 0 20px;
  @include phone {
    padding: 0 15px;
  }
}
