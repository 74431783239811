//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color
$bodyBg: #fafafa;
$secondary: #6c757d;
$success: #44c13c;
$info: #0dcaf0;
$warning: #ffcf0f;
$darkWarning: #ab8e1a;
$danger: #e71b4c;
$light: #f1f1f1;
$gray: #d9d9d9;
$primary: #07364b;
$dark: #212121;
$text-color: #101010;
$gray-900: #0c1116;
$gray-600: #4f5b67;
$gray-800: #242d35;
$gray-700: #373f47;
$red: #eb3800;
$gray100: #949494;
$white: #fff;
$gray200: #f7f7fb;
$border: #eaecee;
$bg-gray: #f4f5f5;
$bg-gray-1: #f5f5f5;
$grey200: #a8b0b9;
$border7: #e7e7e7;
$border8: #efeeeb;
$website-gray: #c4c4c4;

// Font
$font12: 12px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font24: 24px;
$font20: 20px;
$font28: 28px;

// Font Weight
$fWeight500: 500;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
