.devices_svg {
  @media (min-width: 1351px) {
    width: 550px;
  }
}

.note_devices_screens_wrapper {
  position: absolute;
  font-size: 20px;
  background-color: white;
  z-index: 2;

  .note_devices_screen_shot_wrapper {
    .note_devices_screen_shot_image {
      width: 100%;
    }
  }

  .note_device_screen_title_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    .note_device_screen_title {
      font-size: 8px;
    }

    .note_device_screen_title_underline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > img {
        width: 40px;
      }
    }
  }

  .note_device_screen_body_wrapper {
    display: grid;
    grid-template-columns: repeat(4, auto);
    width: 100%;
    margin-top: 10px;

    .note_device_screen_body_photo_wrapper {
      .note_device_screen_body_photo {
        width: 90px;
      }
    }
  }

  .note_device_screen_button_wrapper {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .note_device_screen_button {
      background-color: black;
      color: white;
      padding: 8px;
      margin: 10px;
      font-size: 5px;
    }
  }
}

.phone_devices_screens_wrapper {
  position: absolute;
  background-color: white;
  z-index: 3;

  .phone_devices_screen_shot_wrapper {
    .phone_devices_screen_shot_image {
      width: 82px;
      margin-top: 5px;
    }
  }

  .phone_device_screen_title_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .phone_device_screen_title {
      font-size: 5px;
    }

    .phone_device_screen_title_underline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > img {
        width: 25px;
      }
    }
  }

  .phone_device_screen_body_wrapper {
    display: flex;
    justify-content: column;
    width: 83px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    .phone_device_screen_body_photo_wrapper {
      .phone_device_screen_body_photo {
        width: 83px;
      }
    }
  }

  .phone_device_screen_button_wrapper {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .phone_device_screen_button {
      background-color: black;
      color: white;
      padding: 3px 0;
      margin: 5px 0;
      font-size: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.pad_devices_screens_wrapper {
  position: absolute;
  background-color: white;
  z-index: 1;

  .pad_devices_screen_shot_wrapper {
    .pad_screen_shot {
      width: 245px;
    }
  }

  .pad_device_screen_title_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .pad_device_screen_title {
      font-size: 6px;
    }

    .pad_device_screen_title_underline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > img {
        width: 25px;
      }
    }
  }

  .pad_device_screen_body_wrapper {
    display: flex;
    justify-content: column;
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    .pad_device_screen_body_photo_wrapper {
      .pad_device_screen_body_photo {
        width: 100%;
      }
    }
  }

  .pad_device_screen_button_wrapper {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .pad_device_screen_button {
      background-color: black;
      color: white;
      padding: 5px 0;
      margin: 10px 0;
      font-size: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
