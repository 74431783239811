@import "../../../mixins.scss";

.pdf-modal {
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  overflow: hidden;
  left: 0px;
  position: fixed;
  top: 6rem;
  height: 77%;
  width: 60%;
  z-index: 9999;
  left: 22%;

  .pdf-modal-title {
    align-items: center;
    background-color: #000000;
    color: #fff;
    display: flex;
    padding: 0.5rem;

    .first-title {
      margin-right: auto;
    }
  }

  .closing-btn {
    background-color: $light-purple !important;
    color: rgba(0, 0, 0, 1);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px;
  }

  .pdf-holder {
    flex-grow: 1;
    overflow: auto;
  }

  .pdf-file {
    height: 80rem;
  }
}
.feature-sheet-container {
  margin-top: 4rem;
  padding-left: calc(1px + 55px);
  padding-right: 17.3rem;

  .feature-sheet-info {
    padding-bottom: 5rem;

    .title {
      color: rgba(0, 0, 0, 1);
      font-family: "NunitoSans-SemBold", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 3.4rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 1.2rem;
      margin-top: 4rem;
      padding-right: 3.2rem;
    }

    .quantity-text,
    .size {
      font-family: "NunitoSans-Regular", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: rgba(65, 65, 65, 1);
    }

    .address-holder {
      display: none;
    }
  }

  .scrollbar {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    width: fit-content;
    column-gap: 10.6rem;

    .pdf-holder {
      .feature-sheet-item {
        position: relative;
        width: fit-content;

        .icon {
          position: absolute;
          right: -2rem;
          z-index: 55;
          top: -1rem;
        }

        .pdf-field {
          position: relative;
          border-radius: 5px;
        }

        .hover-button {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;

          &:hover {
            background-color: rgba(26, 26, 26, 0.6);
          }
          .checkbox-item {
            display: none;
          }
          &:hover {
            .checkbox-item {
              display: block;

              .custom-checkbox {
                cursor: pointer;
                display: block;
                display: flex;
                align-items: center;
                margin: 10px 0;
              }

              .custom-checkbox .label {
                font-size: 1.2em;
                margin: 0 10px;
              }

              .custom-checkbox .checkmark {
                position: absolute;
                display: block;
                bottom: 9px;
                right: 9px;
                width: 1.7rem;
                height: 1.7rem;
                border: 1.2px solid #ffffff;
                display: inline-block;
                border-radius: 2px;
                background-color: transparent;
                display: block;
              }

              .custom-checkbox input:checked + .checkmark {
                background-size: 60%;
                content: "";
                background-image: url("./elements/checkmark.svg");
                background-repeat: no-repeat;
                background-size: auto;
                background-position: center;
                background-color: #ffffff;
              }

              .custom-checkbox input {
                display: none;
              }
            }
          }

          .button-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .view-button {
              display: none;
            }

            &:hover {
              .view-button {
                display: block;
                font-size: 14px;
                position: absolute;
                width: 60px;
                height: 25px;
                background-color: $light-purple !important;
                border: 1px solid $light-purple !important;
                border-radius: 5px;
              }
            }
          }
        }
      }

      .pdf-name {
        font-size: 14px;
        line-height: 19px;
        margin-top: 1rem;
      }
    }
  }

  .feature-sheet-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;

    button {
      width: 150px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
      outline: none;
      border: none;
      font-family: NunitoSans-Regular;
      text-transform: uppercase;
      transition: 0.3s linear;
      cursor: pointer;
      border-radius: 5px;
    }

    .first-button {
      padding-right: 2rem;

      .select-all-button {
        background-color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        color: #ffffff;

        &:hover {
          color: $light-purple !important;
        }
      }
    }

    .second-button {
      &:hover {
        .dowload_section {
          display: block;
        }

        .download-all-button {
          color: $light-purple !important;
          background-color: #000000;
          border-radius: 5px;
        }

        .download-all-button::before {
          content: url("../images/arrowUp.svg");
          position: absolute;
          right: 10px;
          width: 1rem;
          border-radius: 5px;
        }
      }

      .download-all-button {
        width: 150px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        transition: 0.3s linear;
        cursor: pointer;
        position: relative;
        background-color: $light-purple !important;
        color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        display: flex;
        justify-content: flex-end;
        padding-right: 2.1rem;
        align-items: center;
        border-radius: 5px;

        .liner_border {
          border-right: 1px solid $dark-purple !important;
          width: 1px;
          height: 80%;
          margin: 0 12px 0 20px;
        }

        &::before {
          content: url("../images/arrowDown.svg");
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 1rem;
        }
      }
    }

    .dowload_section {
      position: absolute;
      margin-left: 15px;
      width: 150px;
      display: flex;
      background: #ffffff;
      z-index: 99;
      display: none;
      border-radius: 0px 0px 5px 5px;

      .download_options {
        display: flex;
        flex-direction: column;

        span {
          font-family: NunitoSans-Regular;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 15px;
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background: #ebebeb;
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .checkmark {
    height: 17px !important;
    width: 17px !important;
  }
  .before_checkbox_section .checkmark:before {
    transform: translateX(7px) rotate(-42deg) !important;
  }

  .before_checkbox_section .checkmark:after {
    transform: translateX(8px) rotate(-58deg) !important;
  }
}

@media (max-width: 960px) {
  .pdf-modal {
    height: 60%;
    width: 60%;

    .closing-btn,
    .first-title {
      font-size: 9px;
    }

    .pdf-holder {
      height: 100%;

      .pdf-file {
        height: 100% !important;
      }
    }
  }
  .feature-sheet-container {
    padding-right: 7rem;
    padding-left: 2rem;

    .feature-sheet-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 18px;
        line-height: 25px;
      }

      .address-holder {
        display: block;
        margin-bottom: 6px;
      }
    }

    .scrollbar {
      width: auto;
      overflow-x: scroll;
      padding: 4rem;
      &::-webkit-scrollbar {
        height: 2px;
        width: 4px;
        border: none;
        position: relative;
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px #bbbaba;
        background-color: #bbbaba;
      }

      .pdf-name {
        font-size: 12px !important;
      }
      .pdf-field {
        max-width: 130.34px;
      }
    }

    .feature-sheet-button-container {
      justify-content: flex-start;
      margin-top: 10px;

      .select-all-button,
      .download-all-button {
        width: 150px;
        height: 35px !important;
      }

      .download-all-button {
        .liner_border {
          margin: 0 24px 0 15px !important;
        }

        &:before {
          width: 10px !important;
          right: 10px !important;
        }
      }
    }
  }
}
