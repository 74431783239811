.form-group {
  margin-bottom: 16px;

  &.date-form {
    display: flex;

    &.start {
      margin-right: 12px;
    }

    .delete-btn {
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 10px;
      z-index: 2;
    }
  }

  &.presentation-container {
    display: flex;
    flex-direction: column;
    label {
      width: 100%;

      .datepicker-label-container {
        display: flex;
      }
    }
  }

  label {
    @include Montserrat(600);
    font-size: $font16;
    line-height: 166.5%;
    color: $dark;
    margin-bottom: 8px;

    span {
      color: $red;
    }
  }

  .form-select,
  .form-control {
    @include border-radius(8px);
    height: 42px;
    @include Montserrat(500);
    font-size: $font14;
    line-height: 166.5%;
    color: $dark;
    border: 1px solid #d6dade;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(33, 33, 33, 0.4);
    }
  }

  textarea.form-control {
    height: auto;
  }
  .relative {
    position: relative;
    .form-control {
      &:disabled {
        background: #fafafa;
      }
    }
    .checkboxOverlay {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      .checkbox-style {
        + {
          label {
            &::before {
              top: 0;
            }
          }
        }
        &:checked {
          + {
            label {
              &:before {
                background-color: $success;
                border-color: $success;
              }
            }
          }
        }
      }
    }
  }
  .iconHolder {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 24px;
      transform: translate(0, -50%);
    }
  }
}

.datePicker {
  position: relative;
  min-width: 250px;
  display: flex;
  align-items: center;

  i {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 42px;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-style: dashed;
      border-left: none;
      border-width: 1px;
      border-color: #808080;
    }
  }

  .form-control {
    padding-left: 76px;
  }
}

.footerCta {
  padding: 16px 0;
  text-align: center;

  .btn {
    min-width: 210px;
  }
}

.checkbox-style {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  height: 0;
  line-height: 16px;

  + {
    label {
      line-height: 16px;
      cursor: pointer;
      margin-bottom: 0 !important;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 2px solid #d6dade;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -3px;
      }
    }
  }

  &:checked {
    + {
      label {
        &::before {
          background-image: url(/assets/images/check.svg);
          background-color: $body-color;
          border-color: $body-color;
        }
      }
    }
  }
}

.radio-style {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  height: 0;
  line-height: 16px;

  + {
    label {
      cursor: pointer;
      display: inline-flex;
      align-items: center;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #ffffff;
        border: 2px solid #d6dade;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        background-repeat: no-repeat;
        background-position: center;
      }

      span {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: $gray-700;
      }
    }
  }
  &:checked {
    + {
      label {
        &::before {
          background-image: url(/assets/images/check.svg);
          background-color: $body-color;
          border-color: $body-color;
        }
      }
    }
  }
}
