// Variables


//   COLORS VARIABLES 
$white: #fff; 
$black: #000;
$primary_color: #E71B4C;
$secondary_color: #07364B; 
$ghost-white: #F9F9F9;

 



//   FONTS VARIABLES
 
$font40:40px;
$font18: 18px;
$font16: 16px;
$font15: 15px;
$font14: 14px;
 