.btn {
  @include poppins(600);
  line-height: 166.5%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  &.cta-primary {
    color: $white;
    background-color: $dark;
  }

  &.cta-outline-primary {
    border: 1px solid $primary;
  }

  &.cta-rounded{
    @include border-radius(32px);
  }
}


.btn-rounded {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: $white;
  border: 1px solid $border;
  @include border-radius(50%);

}
