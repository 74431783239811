@import "../../../mixins.scss";

.insta_section {
  margin-top: 3.8rem;
  padding-left: 6rem;
  padding-right: 20rem;

  .title_holder {
    .social_media_title {
      padding-bottom: 10px;
    }

    @include ipad {
      display: none;
    }
  }

  @include not {
    padding-left: 40px;
    padding-right: 88px !important;
  }

  @include ipad {
    padding-left: unset;
    padding-right: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .insta_video {
    width: 100%;
    display: flex;
    justify-content: center;
    @include phone {
      padding-top: 10px;
    }
  }

  h2 {
    font-family: "NunitoSans-Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.4rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @include ipad {
      text-align: center;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .quentity_text {
    font-family: "NunitoSans-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;

    color: #414141;
    @include ipad {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .insta_show_section {
    width: 100%;
    max-width: 40rem;
    min-width: 40rem;
    padding-left: 2rem;

    @include ipad {
      min-width: unset !important;
      max-width: 100%;
    }
  }

  .insta_section_item {
    margin-top: 4.4rem;
    margin-bottom: 5rem;
    display: flex;

    @include ipad {
      flex-direction: column;
      margin-top: 4px;
      margin-bottom: 20px;
      padding-left: 0;
    }

    .insta_show_section {
      @include ipad {
        display: flex;
        justify-content: center;
      }
    }

    .insta_right_section {
      margin-left: 88px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include not {
        margin-left: 76px;
      }

      @include ipad {
        width: 426px;
        margin-left: 0;
        margin-top: 30px;
      }
      @include phone {
        width: unset;
      }

      .instagram_address_holder {
        display: none;

        @include phone {
          display: block;
          padding-left: 15px;
        }
      }

      .btn_wrapper {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 8rem;
        margin-right: 0;
        gap: 10px;
        @include ipad {
          justify-content: flex-start;
          margin-top: 10px;
          margin-left: 15px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        padding-top: 3.9rem;
        @include ipad {
          margin-top: 0;
          padding: 0 15px;
        }

        a {
          font-family: "NunitoSans-Light" sans-serif;
          font-style: normal;
          font-size: 12px;
          line-height: 16px;
          text-decoration-line: underline !important;
          @include ipad {
            font-size: 12px;
            line-height: 16px;
          }
          @include phone {
            display: none;
          }
        }
      }

      h3 {
        font-family: "NunitoSans-SemiBold";
        font-style: normal;
        font-size: 2.2rem;
        line-height: 3rem;
        margin: 9px 0;
        color: #000000;
        @include ipad {
          font-size: 15px;
          line-height: 20px;
          margin: 5px 0;
        }
      }

      .insta_section_text {
        width: 60%;
        font-family: "NunitoSans-Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        @include ipad {
          font-size: 12px;
          line-height: 16px;
        }

        .ifno {
          font-size: 12px;
        }

        color: rgba(0, 0, 0, 0.7);
        @include not {
          width: 100%;
        }

        @include ipad {
          width: 100%;
        }

        @include phone() {
          width: 85%;
        }
      }

      .download_btn {
        width: 150px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        outline: none;
        border: none;
        font-family: "NunitoSans-Regular";
        text-transform: uppercase;
        transition: 0.3s linear;
        cursor: pointer;
        border-radius: 5px;
        position: relative;
        background-color: $light-purple !important;
        color: #000000;
        padding-left: 0 !important;

        > span {
          font-size: 16px;
        }

        @include ipad {
          font-size: 12px;
          line-height: 16px;
          width: 135px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          color: $light-purple !important;
          background-color: #000000;
        }
      }
    }
  }

  .insta {
    position: relative;
    max-width: 218px;
    max-height: 461px;
    background-image: url("../images/instagramBody.svg");
    background-repeat: no-repeat;

    > iframe {
      margin-top: 4%;
      margin-left: 1px;
      max-width: 202px;
      height: 422px;
      border: none;
      z-index: 999;
    }

    > img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    > .play {
      position: absolute;
      left: 55%;
      top: 56%;
      height: 3.5rem;
      width: 3.5rem;
      z-index: 3;
      transform: translate(-50%, -50%);
      background: $light-purple !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include ipad {
        width: 25px;
        height: 25px;
      }

      &.hide {
        display: none;

        + img {
          display: none;
        }
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-left: 1rem solid black;
        border-bottom: 0.5rem solid transparent;
        @include ipad {
          border-top: 5px solid transparent;
          border-left: 10px solid black;
          border-bottom: 5px solid transparent;
        }
      }
    }
  }

  .multi-liner {
    width: 84%;
    margin-left: 12rem;
    border: 1px solid #e7e7e7;
    display: flex;
  }

  .liner {
    width: 96.7%;
    display: flex;
    border: 1px solid #e7e7e7;
    margin-left: 4.8rem;
    @include ipad {
      width: 100%;
      margin-left: 0rem;
    }
  }
}

@media (max-width: 1025px) {
  .right {
    .mobile_styling {
      display: flex;
      align-items: center;

      .address {
        display: block;
        font-family: "NunitoSans-Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: #00000066;
        padding-left: 4rem;
        padding-top: 0.5rem;
      }
    }
  }
  .multi-liner {
    display: none !important;
  }
}

@media (max-width: 767.8px) {
  .instagram_video {
    top: 94.5rem !important;
    left: 11.5rem !important;
    width: 121.2rem !important;
    height: 125rem !important;
  }
}
