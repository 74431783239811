.general-popup {
  position: absolute;
  top: 30px;
  left: -50px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  z-index: 9;
  .general-popup-content {
    padding: 20px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .popup-icon {
      position: absolute;
      left: calc(100% - 30px);
      top: -8px;
    }
    & > div {
      width: 100%;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    & a {
      text-decoration: none;
    }
  }
}
