@import "../../../mixins.scss";
$blackColor: #000000;

.copLink-btn,
.copied_Lnkbtn {
  width: 150px;
  height: 40px;
  font-size: 25px !important;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: NunitoSans-Regular;
  text-transform: uppercase;
  cursor: pointer;
  color: $blackColor;

  @include ipad() {
    font-size: 25px !important;
    margin-top: 10px;
    height: 35px;
  }

  .liner_border {
    border-right: 1px solid #bfbfbf;
    width: 1px;
    height: 90%;
    margin: 0 10px;

    :hover {
      border-right: 1px solid $light-purple !important;
    }
  }

  &:nth-child(2) {
    background-color: $light-purple !important;
    color: #fff;
    padding-left: 0 !important;
    margin-left: 20px !important;
    @include ipad {
      margin-left: 17px;
    }
    @include phone {
      margin-left: 10px;
    }

    &:hover {
      color: $light-purple !important;
      background-color: #000000;
    }

    &::before {
      border: none;
      content: unset;
    }
  }

  &:nth-child(1) {
    color: $blackColor;
    margin-left: 15px;
    border-color: #000000;
    background-color: transparent;
    position: relative;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include ipad() {
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-image: url("../../../images/copy.svg");
      background-size: cover;
      width: 16px;
      height: 16px;
      left: 10px;
      top: 50%;
      transform: translateY(-60%);
    }

    @include not() {
      left: 10px;
    }
    @include ipad() {
      left: 10px;
    }
    @include phone() {
      left: 0;
    }
  }

  &:hover {
    background-color: #000000;
    color: $light-purple !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    .liner_border {
      border-color: #1c1c1c;
    }
    &::before {
      background-image: url("../../../images/copy.svg");
    }
  }
}

.copied_Lnkbtn {
  border-radius: 5px;
  background-color: black !important;
  color: #fff !important;

  &:hover {
    color: #fff !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url("../../../images/white-copy-icon.svg") !important;
    background-size: cover;
    width: 15px;
    height: 15px;
    left: 10px !important;
    top: 50%;
    transform: translateY(-60%);
  }
}

.copLink-btn {
  &.custom_url_btn {
    background-color: $light-purple !important;
    border: none;
    @include ipad {
      margin-bottom: 10px;
    }
    @include phone {
      margin-left: 20px;
      margin-bottom: 10px;
    }

    &:hover {
      background-color: #000000 !important;
      .liner_border {
        border-color: #1c1c1c;
      }
    }
    .liner_border {
      border-right: 1px solid $light-purple !important;
      &:hover {
        border-color: #1c1c1c;
      }
    }
  }
}

.copied_Lnkbtn {
  &.custom_url_btn {
    @include phone {
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }
}
