.introMain {
  min-height: calc(100vh - 106px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .caption {
    max-width: 741px;
    margin: 0 auto 36px;
    text-align: center;

    h3 {
      font-weight: 600;
      font-size: $font24;
      line-height: 150%;
      color: $primary;
      margin-bottom: 7px;
    }

    p {
      font-size: $font12;
      line-height: 150%;
      text-align: center;
      color: $gray-600;
    }
  }

  .row {
    width: 100%;
  }
}


.cardIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #EAECEE;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
  cursor: pointer;
  .icon {
    margin-bottom: 16px;
  }

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #242D35;
    text-align: center;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #4F5B67;
  }
}
