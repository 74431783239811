#not-display {
  display: none !important;
}

.sidebar {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  width: 100%;
  background: $white;
  box-shadow: inset -1px 0px 0px $light;
  height: 100vh;
  min-height: 100vh;
  position: sticky;
  top: 0;
  left: 0;

  &.not-display {
    display: none !important;
  }

  .brandname {
    text-align: center;
    padding: 24px 37.5px 28px;
    min-height: 79px;
    margin-bottom: 8px;
  }

  .stickyFooter,
  .mainLinks {
    li {
      .cta-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 15px 0;
        color: $gray-600;
        position: relative;
        cursor: pointer;

        .logout-text {
          color: $danger;
          font-size: $font16;
          font-weight: $fWeight500;
        }

        i {
          padding: 0 24px;
          display: inline-block;
        }

        span {
          flex: 1;
          padding-right: 10px;
          display: block;
        }

        &.active {
          color: $primary;
          background: $gray200;
          font-weight: 500;
          svg {
            path {
              fill: $primary;
            }
          }

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background: $primary;
          }
        }
      }
    }
  }

  .mainLinks {
    flex: 1;
  }

  .user {
    margin: 28px 0;
    display: flex;
    padding: 0 24px;

    .avatar {
      width: 32px;
      height: 32px;
      @include border-radius(50%);
      display: inline-block;
      margin-right: 15px;

      .user-no-image-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1px;
      }
    }

    .media-body {
      flex: 1;

      .name {
        @include Montserrat(500);
        font-size: $font16;
        line-height: 100%;
        color: $gray-600;
      }

      .desig {
        line-height: 150%;
        @include Montserrat(400);
        font-size: $font12;
        line-height: 100%;
        color: $gray-600;
      }
    }
  }

  .logout-container {
    padding-left: 40px;
    cursor: pointer;
    color: $gray-600;
    font-size: $font14;
  }

  .copyrights {
    @include Montserrat(400);
    font-size: $font12;
    line-height: 150%;
    color: $gray-600;
    padding: 24px;

    strong {
      @include Montserrat(600);
      color: $primary;
    }
  }
}

@include media-breakpoint-down(xl) {
  .sidebar {
    max-width: 200px;
  }

  .sidebar .brandname {
    padding: 18px 16px;
  }

  .sidebar .stickyFooter li .cta-link,
  .sidebar .mainLinks li .cta-link {
    padding: 12px 0;
    font-size: 14px;
  }

  .sidebar .user .avatar {
    margin-right: 4px;
  }

  .sidebar .stickyFooter li .cta-link i,
  .sidebar .mainLinks li .cta-link i {
    padding: 0 10px;
  }

  .sidebar .user {
    padding: 0 10px;
  }

  .sidebar .copyrights {
    padding: 15px 10px;
  }

  .sidebar .user .media-body .name {
    font-size: 14px;
  }
}

@include media-breakpoint-down(lg) {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    transform: translateX(-100%);
    transition: all 0.3s;
    max-width: 240px;
  }

  .mobileSidebarHeader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .brandname {
      display: flex;
      align-items: flex-end;
    }
  }

  .showSidebar {
    transform: translateX(0) !important;
    max-width: 100vw;
    width: 100vw;
  }

  .showMenu .sidebar {
    transform: translateX(0);
  }
  .sidebar .user .media-body .name {
    line-height: 150%;
  }
}
