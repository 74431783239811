@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@700&family=Montserrat:wght@400;500;600;700&family=Poppins:wght@400;600&display=swap');

@mixin Montserrat($font-weight) {
    font-family: 'Montserrat', sans-serif;
    font-weight: $font-weight;
}
@mixin Poppins ($font-weight) {
    font-family: 'Poppins', sans-serif;
    font-weight: $font-weight;
}
@mixin FiraSansCondensed ($font-weight) {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-weight: $font-weight;
}
 
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin transform($transform){
    -webkit-transform: $transform;
    -moz-transform: $transform;
    transform: $transform;
}
