// Bootstrap and its default variables
@import "../css/bootstrap.min.css";
@import "abstracts.scss/_mixins.scss";
@import "abstracts.scss/_variables.scss";
@import "base.scss/_reset.scss";
@import "base.scss/_typography.scss";
@import "components.scss/_buttons.scss";
@import "components.scss/_tabs.scss";
@import "components.scss/_testimonial.scss";
@import "components.scss/_video_modal.scss";
@import "layout.scss/_header.scss";
@import "layout.scss/_banner.scss";
@import "layout.scss/_footer.scss";

@mixin phone {
  @media (max-width: 992.8px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

.hide {
  position: relative;
}

html {
  font-size: 0.521vw;
  @media screen and (min-width: 1921px) {
    font-size: 10px;
  }
}

html {
  overflow-y: auto;
  overflow-x: hidden;
  background: #f4f4f4;
  width: 100%;

  body {
    width: 100%;

    > div {
      width: 100%;

      > div {
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  }
}

a {
  text-decoration: none !important;
}

@font-face {
  font-family: NunitoSans-Regular;
  src: url("../assets/fonts/NunitoSans-Regular.ttf");
}

@font-face {
  font-family: NunitoSans-Italic;
  src: url("../assets/fonts/NunitoSans-Italic.ttf");
}

@font-face {
  font-family: NunitoSans-Bold;
  src: url("../assets/fonts/NunitoSans-Bold.ttf");
}

@font-face {
  font-family: NunitoSans-Black;
  src: url("../assets/fonts/NunitoSans-Black.ttf");
}

@font-face {
  font-family: NunitoSans-ExtraBold;
  src: url("../assets/fonts/NunitoSans-ExtraBold.ttf");
}

@font-face {
  font-family: NunitoSans-SemiBold;
  src: url("../assets/fonts/NunitoSans-SemiBold.ttf");
}

@font-face {
  font-family: NunitoSans-Light;
  src: url("../assets/fonts/NunitoSans-Light.ttf");
}

@font-face {
  font-family: Roboto-Bold;
  src: url("../assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: RobotoCondensed-Regular;
  src: url("../assets/fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: Roboto-Light;
  src: url("../assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("../assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Cardo-Bold;
  src: url("../assets/fonts/Cardo-Bold.ttf");
}

@font-face {
  font-family: Cardo-Italic;
  src: url("../assets/fonts/Cardo-Italic.ttf");
}

@font-face {
  font-family: Cardo-Regular;
  src: url("../assets/fonts/Cardo-Regular.ttf");
}

/* CUSTOM STYLES
-------------------------------------------------- */
.wrapper {
  overflow: hidden;
}

.title {
  text-align: center;

  h2 {
    font-size: $font40;
    color: $secondary_color;
  }
}

.highlights-sec {
  padding: 70px 0 50px;

  .highlights-inner {
    padding: 25px 0 0 0;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        text-align: center;
        padding: 15px 30px;

        span {
          display: block;
        }

        h4 {
          font-size: $font16;
          color: $secondary_color;
          @include Poppins(400);
          margin: 15px 0 4px;
        }

        strong {
          @include Poppins(600);
          font-size: $font14;
          display: block;
          color: $secondary_color;
        }
      }
    }
  }

  .high_lights_title {
    text-transform: capitalize;
  }
}

/****** MATTERPORT SEC *****/
.matterport-inner {
  position: relative;
  margin: 40px 0 0 0;

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/******  OPEN HOUSE SEC ******/
.open-house-sec {
  background-color: $ghost-white;
  padding: 45px 0;

  .open-house-inner {
    max-width: 361px;
    width: 100%;
    margin: 35px auto 0;

    ul {
      margin: 0 0 35px 0;

      li {
        display: flex;
        align-items: center;
        font-size: $font16;
        color: $secondary_color;
        margin-bottom: 15px;
        @include Montserrat(700);

        img {
          margin-right: 10px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

/******  GALLERY SEC ******/
.gallery-sec {
  padding: 27px 0 40px 0;
  background-color: $ghost-white;

  .gallery-inner {
    margin: 50px 0 0 0;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2.5px 35px;

      li {
        width: 25%;
        padding: 3px 2.5px;

        img {
          width: 100%;
        }
      }
    }
  }
}

.lg-backdrop {
  background-color: rgba($black, 0.8);
}

/******  PROPERTY DETAIL SEC ******/
.property-detail-sec {
  padding: 40px 0;

  .text-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: $font40;
      color: $secondary_color;
    }

    h3 {
      font-size: $font18;
      color: $black;
      margin: 30px 0 10px;
      @include Montserrat(700);
    }

    p {
      line-height: 17px;
      font-size: $font14;
      color: $black;
      @include Montserrat(500);
      margin: 0 0 30px 0;
      max-width: 570px;
      width: 100%;
    }

    h4 {
      font-size: $font18;
      color: $black;
      @include Montserrat(700);
      margin: 0 0 13px 0;
    }

    .media {
      display: flex;
    }

    ul {
      margin-right: 54px;

      &:last-child {
        margin: 0;
      }

      li {
        color: $black;
        font-size: $font14;
        position: relative;
        padding: 0 0 0 30px;
        @include Montserrat(500);
        margin: 0 0 16px 0;

        &:after {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          height: 3px;
          width: 20px;
          background-color: $secondary_color;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

/******  NEIGHBORHOOD INFO SEC ******/
.neighborhood-info-sec {
  padding: 16px 0;

  .text-box {
    background-color: $secondary_color;
    padding: 90px 58px;

    h2 {
      color: $white;
    }

    h3 {
      color: $white;
    }

    h4 {
      color: $white;
    }

    p {
      color: $white;
    }

    ul {
      li {
        color: $white;

        &::after {
          background-color: $white;
        }
      }
    }
  }

  .image-holder {
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

/****** FLOOR PLANS SEC *****/
.floor-plans-sec {
  padding: 45px 0 40px;

  .floor-plan-inner {
    margin: 50px 0 0 0;

    .image-holder {
      margin: 30px;
    }
  }
}

/****** TESTIMONIAL SEC *****/
.testimonial-sec {
  background-color: $ghost-white;
  padding: 35px 0;

  .testimonial-inner {
    margin: 10px 0 0 0;
  }
}

@import "components.scss/_responsive.scss";
