@import "../../mixins.scss";

.modal-background {
  width: 100vw;
  height: 100vh;
  background: black;
  position: absolute;
  left: 0;
  top: 0;
}

.modal_cover_section {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.6);
  display: flex;

  .image_modal_section {
    width: 100%;
    height: 100%;
    margin: unset;

    .image_section {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: fixed;
      margin: 0 auto;
      right: 0;
      left: 0;
      background: transparent;
      transform: translateX(0%);
      animation-duration: 800ms;

      @media (max-width: 1350px) {
        max-width: 80%;
      }
      @include phone {
        min-width: unset;
        max-width: unset;
      }

      .swiper-slide {
        width: 106.3rem !important;
        @include phone {
          width: 322px !important;
        }

        .image_modal {
          width: 100%;
          height: auto !important;
          max-width: 106.3rem;
          max-height: 70.7rem;

          @include phone {
            max-width: 322px;
            max-height: 231px;
          }
        }
      }

      .close_section {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: -22.1%;
        z-index: 2;

        .closing-button {
          z-index: 5;
          cursor: pointer;
        }

        @media (max-width: 1445px) {
          margin-top: -23.1%;
        }

        @media (max-width: 1350px) {
          margin-top: -29%;
        }
        @media (max-width: 1000px) {
          margin-top: -32%;
        }
        @include phone {
          margin-top: -170px;
        }
      }
    }
  }

  .image_section.animate__zoomOut {
    animation-duration: 800ms !important;
  }

  .btn_section {
    position: fixed;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    left: 16%;
    right: 16%;
    z-index: 99999;

    > img {
      z-index: 9999999999;
    }

    .prev_image_icon,
    .next_image_icon {
      cursor: pointer;
    }

    @media (max-width: 1350px) {
      right: 10%;
      left: 10%;
    }
    @include phone {
      left: 0;
      right: 0;
      opacity: 0;
    }
  }
}

.hide_modal {
  display: none;
}

.image_section.animate__zoomOut {
  animation-duration: 800ms !important;
}

.modal_cover_section {
  .image_modal_section {
    .image_section {
      @include ipad {
        min-width: 600px;
      }
      @include phone {
        min-width: unset;
      }

      .image_modal {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.modal_cover_section_top,
.modal_cover_section_bottom {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
}

.close_section_imitation {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  left: calc(50% - 21px);
  bottom: 14px;
  cursor: pointer;
}
