@import "../../../../mixins.scss";

.video_and_matterport {
  margin-top: 1rem;

  .main_information,
  .quantity_holder {
    padding-left: 6rem;
    padding-right: 17rem;
    display: flex;
    flex-direction: inherit;
    align-items: flex-start;
    font-weight: 400;
    font-family: "NunitoSans-Regular";
    font-size: 12px !important;
    font-style: normal;

    .title {
      font-family: "NunitoSans-Bold";
      font-style: normal;
      font-size: 2.5rem;
      line-height: 3.4rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 12px;
      padding-right: 3.6rem;
      color: #000000;
    }

    .video_subtitle {
      display: none;
    }

    .videos_address_holder {
      padding-top: 5px;
    }
  }
}

.grid-container {
  padding-left: 6rem;
  padding-right: 20rem;
  height: auto;
  display: grid;
  grid-template-columns: repeat(1, minmax(calc(100%), 1fr)) !important;
  grid-column-gap: 22px;

  .grid-item {
    max-width: 426px;
    display: flex;
    flex-direction: column;
    padding-top: 4.3rem;
    font-size: 1rem;
    text-align: center;
    text-align: inherit;

    .videos {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-top: 56%;
      border-radius: 5px;

      .video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        border: none;
      }
    }

    .no_mob {
      display: none;
    }

    .size {
      padding-top: 1.9rem;
    }

    .video_title {
      font-family: "NunitoSans-SemiBold";
      font-style: normal;
      font-size: 2.2rem;
      line-height: 30px;
      padding-top: 2.5rem;
      color: #000000;
      font-weight: unset;
      @media (max-width: 991px) {
        padding-top: 16px;
      }
    }

    .description {
      font-family: "NunitoSans-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.7);
      width: 90%;
    }

    .learn_more {
      font-family: "NunitoSans-Light", sans-serif;
      font-size: 12px;
      padding-bottom: 1rem;
      text-decoration: underline !important;
    }

    .multi-liner {
      display: none;
    }
  }

  .small-liner {
    display: none;
  }
  .video_button_wrapper {
    display: flex;
    flex-direction: row;
    @media (max-width: 1100px) {
      align-items: flex-end;
    }

    .download_btn_wrapper {
      margin-bottom: 4rem;
      margin-right: 20px;
      @media (max-width: 1100px) {
        margin-bottom: unset;
        margin-right: unset;
      }

      .download_btn {
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        border-radius: 5px;
        width: 150px;
        height: 40px;
        line-height: 16px;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        transition: 0.3s linear;
        cursor: pointer;
        position: relative;
        background-color: $light-purple !important;
        padding-left: 0 !important;
        display: flex;
        justify-content: flex-end;
        padding-right: 21px;
        align-items: center;
        color: #000000;
        border-radius: 5px;

        > span {
          font-size: 16px;
        }
      }

      .download_btn:hover {
        color: $light-purple !important;
        background-color: #000000;
      }
    }
  }
}

.multi-liner {
  width: 71.9%;
  display: flex;
  border: 1px solid #e7e7e7;
  position: relative;
  left: 11%;
  margin-top: unset;
  margin-bottom: 35px;
}

@media (max-width: 1222px) {
  .video_and_matterport {
    display: flex;
    flex-direction: column;

    .main_information,
    .quantity_holder {
      padding-top: 6px;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .button_block {
        display: block;
        padding-top: 5px;
      }

      .title {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: unset;

        .matterport_title,
        .slash {
          display: none;
        }
      }

      .video_subtitle {
        display: block;
        font-family: NunitoSans-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 30px;
        text-align: center;
        text-transform: initial !important;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: -6px;
      }
    }
  }

  .grid-container {
    grid-template-columns: auto;
    height: auto;

    .video-container {
      align-self: center;
      justify-self: center;
      min-width: 426px;
    }

    .grid-item {
      width: unset;
      padding: 0;

      .videos {
        border-radius: 0px;

        .video {
          width: auto;
        }
      }

      .video_title {
        font-size: 15px;
        line-height: 20px;
      }

      .quantity_text,
      .size {
        font-size: 10px !important;
        line-height: 16px;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
      }

      .learn_more {
        display: none;
      }
    }

    .download_btn {
      justify-content: center;
      font-size: 12px;
      line-height: 16px;
      width: 150px;
      height: 35px;

      > span {
        font-size: 16px;
      }
    }

    .matterport {
      display: none;
    }

    .small-liner {
      display: block;
      width: 84%;
      margin-left: 12rem;
      border: 1px solid #e7e7e7;
      display: flex;
    }

    .multi-liner {
      display: block !important;
      width: 100%;
      position: relative;
      left: 0;
      border: 1px solid #e7e7e7;
      margin-bottom: 15px;
      top: 10px !important;
    }

    .multi-liner.unbranded-liner {
      margin-bottom: 0 !important;
    }
  }

  .grid-container {
    grid-template-columns: auto;
    height: auto;

    .grid-item {
      width: unset;

      .video_title {
        font-size: 15px;
        line-height: 20px;
      }

      .quantity_text,
      .size {
        font-size: 10px !important;
        line-height: 16px;
        padding-left: 15px;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
      }

      .learn_more {
        display: none;
      }
    }

    .download_btn {
      justify-content: center !important;
      font-size: 12px;
      line-height: 16px;
      width: 150px;
      height: 35px;

      > span {
        font-size: 16px;
      }
    }

    .matterport {
      display: none;
    }
  }

  .grid-container {
    grid-template-columns: auto;
    height: auto;

    .grid-item {
      width: unset;

      .video {
        width: auto;
      }

      .video_title {
        font-size: 15px;
        line-height: 20px;
        padding-left: 15px;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
        padding-left: 15px;
      }
    }

    .download_btn_wrapper {
      padding-left: 15px;
      padding-top: 10px;
    }
  }
}

@media (max-width: 500px) {
  .grid-container {
    padding-left: unset;
    padding-right: unset;

    .video-container {
      min-width: unset;
      align-self: unset;
      justify-self: unset;
    }

    .grid-item {
      margin-right: 0;
      padding-top: 6px;

      .size {
        padding-top: 15px;
      }
    }

    .download_btn {
      padding-right: 0px !important;

      > span {
        font-size: 16px;
      }
    }

    .multi-liner {
      width: 85.4%;
      left: 7.8%;
    }
  }
}

.branded_unbranded_video-container {
  position: relative;

  .branded_unbranded_copy_button {
    position: absolute;
    bottom: 33px;
    right: 2px;

    @include phone {
      bottom: 26px;
      right: 0;
      left: 140px;
    }
  }
}
