 .banner-sec {
     position: relative;

     &::after {
         position: absolute;
         content: "";
         left: 0;
         width: 100%;
         top: 0;
         height: 122px;
         background: linear-gradient(180deg, rgba($secondary_color, 0.7) 0%, rgba($secondary_color, 0) 100%);
     }

     .banner-inner {
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         padding: 195px 0 0 0;

         .text-box {
             background-color: $white;
             border-top: 10px solid $secondary_color;
             max-width: 281px;
             width: 100%;
             padding: 19px 16px 17px;
             @include border-radius(10px);

             ul {
                 li {
                     display: flex;
                     margin: 0 0 18px 0;
                     align-items: center;

                     span {
                         display: flex;
                         margin-right: 10px;
                         align-items: center;
                         justify-content: center;
                     }

                     p {
                         color: $black;
                         font-size: $font15;
                     }
                 }
             }

         }
     }
 }