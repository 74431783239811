@import "../../mixins.scss";

.faq__title {
  font-family: NunitoSans-SemiBold, sans-serif;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
}

@media (max-width: 1023px) {
  .services {
    display: block !important;
  }
}

.download_element_download_progress {
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 100;
  @include phone {
    left: 7px;
  }
}
