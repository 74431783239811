.all-content {
    min-height: 600px;
    display: flex;
    align-items: baseline;
    background-color: #f4f4f4;
    padding-bottom: 24px ;
    position: relative;
}

@media(max-width: 1023px){
  .all-content{
    display: block !important;
  } 
}