.readOnly-input {
  border: none;
  outline: none;
}

.delete-btn {
  border: none;
  background-color: transparent;
  color: $red;
  cursor: pointer;
}

.highlight-item-container {
  display: flex;
}

.highlight-title {
  width: 100%;
}

.cardWebsite {
  margin-top: 16px;

  .head-buttons-container {
    display: flex;
    flex-direction: column;
  }

  .cta-outline-primary {
    margin-left: auto;
    font-size: 12px;
    height: 26px;
    border-radius: 12px;
  }

  .headerTitle {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;

    .headerTitle-first-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    h4 {
      margin: 0;

      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 2px solid #d6dade;
        border-radius: 4px;
        width: 16px;
        height: 16px;
        margin-left: 16px;

        img {
          display: none;
        }

        &.active {
          background: #07364b;
          border: 1px solid #07364b;

          img {
            display: inline-block;
          }
        }
      }
    }

    .btn {
      margin-left: auto;
      font-size: 12px;
      height: 26px;
      border-radius: 12px;
    }
  }
}

.ctaHighlight {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #242d35;
  border: 1px solid #eaecee;
  border-radius: 8px;
  padding: 10px 16px;
  text-decoration: none;
  margin-top: 8px;

  .cardBody {
    margin-top: 8px;
    border-bottom: 0.5px solid #ffffff;
    width: 100%;
  }

  .display-toggle {
    margin-left: auto;

    form {
      display: flex;
      column-gap: 5px;
    }

    .toggle-display-label {
      font-size: 10px;
      display: flex;
      align-items: center;
      column-gap: 2px;
    }

    .active-toggle-form {
      .toggle-display-label {
        color: #fff;
      }
    }

    input {
      accent-color: $primary;
    }
  }

  &.active {
    background: #07364b;
    color: #ffffff;

    .cardBody {
      color: #ffffff;
    }
  }
}

.propertyHighlights {
  max-height: 188px;
  overflow-x: hidden;
  overflow-y: auto;

  .ctaHighlight {
    cursor: pointer;
    .cardBody {
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      outline: none;
    }
  }
}

.labelHeader {
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  label {
    margin: 0;
    font-size: 14px;
  }
}
.ctaSave {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-decoration: none;
  color: #e71b4c;
  border: none;
  background-color: transparent;
  margin-left: auto;
  margin-bottom: 20px;
  :hover {
    color: $primary;
  }
}

.agentCard {
  padding: 16px;
  border: 2px solid #eaecee;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;

  .avatar {
    margin-bottom: 16px;

    img {
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.uploadImg {
  width: 100%;
  background: linear-gradient(0deg, #eaecee, #eaecee);
  border-radius: 4px;
  min-height: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.file-style {
  position: absolute;
  height: 0;
  opacity: 0;
  visibility: hidden;

  + {
    label {
      cursor: pointer;
    }
  }
}

.cta-remove {
  background: #e71b4c;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 14px;
  top: -12px;
}

.previewVideo {
  text-align: center;

  .video {
    height: 315px;
    background: #c4c4c4;
    background: #c4c4c4;
    border: 7px solid #1a1a1a;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 22px;
  }

  .btn {
    border: 1px solid #07364b;
    border-radius: 32px;
    width: 151px;
    height: 38px;
    font-size: 14px;
  }
}

@include media-breakpoint-down(sm) {
  .cardWebsite .headerTitle {
    flex-direction: column;
    align-items: flex-start;
  }
}
