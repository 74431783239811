@import "../../../../mixins.scss";

.grid-item {
  display: flex;
  flex-direction: column;
  padding-top: 4.3rem;
  font-size: 1rem;
  text-align: center;
  text-align: inherit;

  .video {
    width: 375px;
    height: 208.12px;
  }

  .no_mob {
    display: none;
  }

  .quantity_text,
  .size {
    font-family: NunitoSans-Regular, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: rgba(65, 65, 65, 1);
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.4);
  }

  .size {
    padding-top: 1.9rem;
  }

  .video_title {
    font-family: "NunitoSans-SemiBold", sans-serif;
    font-weight: unset;
    font-style: normal;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 30px;
    color: #000000;
  }

  .description {
    font-family: NunitoSans-Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
    width: 90%;
  }

  .learn_more {
    font-size: 12px;
    padding-bottom: 1rem;
    text-decoration: underline !important;
  }
}

.copy-link-btn,
.embed-code-btn,
.video_copy_btn,
.after_copy_embed_btn {
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  line-height: 16px;
  outline: none;
  border: none;
  font-family: NunitoSans-Regular;
  transition: 0.3s linear;
  cursor: pointer;
  position: relative;
  background-color: $light-purple !important;
  color: #000000;
  padding-left: 0 !important;
  display: flex;
  justify-content: flex-end;
  padding-right: 21px;
  align-items: center;

  &:hover {
    color: $light-purple !important;
    background-color: #000000;
  }
}

.video_copy_btn,
.after_copy_embed_btn {
  color: #ffff !important;
  background-color: #000000;

  &:hover {
    color: #ffff;
  }

  &::before {
    content: "";
    position: absolute;
    background-size: cover;
    width: 16px;
    height: 16px;
    left: 10px;
    top: 55%;
    transform: translateY(-60%);
    transition: 0.3s all linear;
    content: url("../../images/copiedURL.svg") !important;
  }
}

.liner_border {
  border-right: 1px solid $dark-purple;
  width: 1px;
  height: 90%;
  margin: 33px;
}

.embed_liner_border {
  border-right: 1px solid $dark-purple;
  width: 1px;
  height: 90%;
  margin: 0 17px;
}

.btn_wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 8rem;
}

.embed-code-btn {
  margin-left: 7px;

  &::before {
    content: "";
    position: absolute;
    background-size: cover;
    width: 16px;
    height: 16px;
    left: 10px;
    top: 55%;
    transform: translateY(-60%);
    transition: 0.3s all linear;
    content: url("./icons/embed.svg");
  }
}

.copy-link-btn {
  margin-left: 7px;

  &::before {
    content: "";
    position: absolute;
    background-size: cover;
    width: 16px;
    height: 16px;
    left: 10px;
    top: 55%;
    transform: translateY(-60%);
    transition: 0.3s all linear;
    content: url("./icons/copy.svg");
  }
}

.video_copy_btn {
  margin-left: 0.7rem !important;

  .liner_border {
    border-right: 1px solid white !important;
    margin: 0 19px 0 20px !important;
  }

  &::before {
    content: "";
    position: absolute;
    background-size: cover;
    width: 16px;
    height: 16px;
    left: 10px;
    top: 50%;
    transform: translateY(-60%);
    transition: 0.3s all linear;
    content: url("./icons/copy.svg");
  }

  .copiedLink {
    padding-right: 30px !important;
  }
}

.after_copy_embed_btn {
  .embed_liner_border {
    border-right: 1px solid white !important;
    margin: 0 21px 0 20px !important;
  }

  .copied {
    padding-right: 30px !important;
  }
}

.embed-code-btn {
  &:hover {
    &::before {
      content: url("./icons/embed-hover.svg");
    }
  }
}

.copy-link-btn {
  &:hover {
    &::before {
      content: url("./icons/copyOnHover.svg");
    }
  }
}

@media (max-width: 1550px) {
  .btn_wrapper {
    margin-right: 0rem;
  }
}

@media (max-width: 1330px) {
  .btn_wrapper {
    margin-right: -6rem;
  }
}

@media (max-width: 1222px) {
  .btn_wrapper {
    padding-left: 15px !important;
    padding-top: 10px;
    margin-right: 0;
    justify-content: flex-start !important;

    .embed-code-btn,
    .copy-link-btn {
      justify-content: center;
      font-size: 12px;
      line-height: 16px;
      height: 35px;
      align-items: center;
    }

    .embed-code-btn {
      margin-left: 5px !important;
    }

    .liner_border {
      margin: 0 10px 0 4px;
    }

    .embed-liner_border {
      margin: 0 9px 0 27px;
    }
  }
}

@media (max-width: 1100px) {
  .btn_wrapper {
    margin-right: -10rem;
  }
}

@media (max-width: 960px) {
  .grid-item {
    width: unset;

    .no_mob {
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;

      .matterport_title {
        font-size: 18px !important;
        line-height: 25px;
        color: black;
        font-family: NunitoSans-Bold, sans-serif;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 1.2rem;
        margin-top: 4rem;
        padding-right: 3.2rem;
      }

      .address {
        display: block;
        font-family: NunitoSans-Regular, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.4);
        padding-bottom: 3.5rem;
      }
    }

    #matterport-vid {
      width: 100% !important;
      max-width: 430px !important;
      max-height: 236px !important;
    }

    .video_title {
      font-size: 15px;
      line-height: 20px;
      padding-left: 15px;
    }

    .quantity_text,
    .size {
      font-size: 10px !important;
      line-height: 16px;
      padding-left: 15px;
    }

    .description {
      font-size: 12px;
      line-height: 16px;
      padding-left: 15px;
    }

    .learn_more {
      display: none;
    }
  }
}

.download_btn_matterport {
  width: 150px;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  border: none;
  font-family: NunitoSans-Regular;
  transition: 0.3s linear;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  background-color: $light-purple !important;
  color: #000000;
  padding-left: 0 !important;
  display: flex;
  justify-content: flex-end;
  padding-right: 21px;
  align-items: center;
  text-transform: uppercase;
}
