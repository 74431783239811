.address_bar {
  height: 16.1rem;
  color: rgba(26, 26, 26, 1);
  font-family: NunitoSans-Regular, sans-serif;
  text-transform: uppercase;
  position: relative;
  padding-left: 1.5rem;
  top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 10px;

  @media (max-width: 1400px) {
    margin-bottom: 15px;
  }

  .city {
    padding-bottom: 0.7rem;
    padding-top: 15px;
    font-weight: 700;

    @media (max-width: 1050px) {
      padding-top: 7px;
    }
  }

  .info {
    padding-bottom: 1rem;
  }

  .button {
    padding: 1rem;
  }

  .name {
    font-size: 1.4rem;
    font-family: NunitoSans-SemiBold, sans-serif;
  }

  #clock {
    padding-bottom: 0.2rem;
    padding-right: 10px;
  }

  h1 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-family: "NunitoSans-Bold", sans-serif;
    font-weight: 700;
  }

  h2 {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-family: "NunitoSans-SemiBold", sans-serif;
    color: rgba(26, 26, 26, 0.4);
    letter-spacing: 0.05rem;
  }

  .name_of_agent {
    font-family: "NunitoSans-Regular", sans-serif;
    font-size: 1.2rem;
  }

  .date_of_shoot,
  .delivery_date,
  .bold_text {
    font-size: 1.4rem;
    line-height: 1.9rem !important;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: "NunitoSans-SemiBold", sans-serif;
    font-style: normal;
  }

  .date {
    font-family: "NunitoSans-Regular", sans-serif;
    font-size: 1.4rem;
  }

  .icons {
    padding-top: 2.216rem;
    position: relative;
    top: -1.5rem;

    img {
      padding-right: 0.75rem;
    }
  }

  .white-liner {
    position: relative;
    border: 1px solid white !important;
    left: -2rem !important;
    top: -1.5rem;
  }
}

@media (max-width: 1023px) {
  .address_bar {
    display: none;
  }
}

.address_bar_unit {
  font-family: "NunitoSans-Bold", sans-serif;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #1a1a1a;
}
