.table-responsive {
  .table {
    tr {
      td {
        height: 60px;

        .user {
          display: flex;
          align-items: center;

          .avatar {
            display: flex;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 8px;
            font-size: 14px;
            line-height: 150%;
            color: #000000;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .date {
          display: flex;
          align-items: center;

          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
}


@include media-breakpoint-down(md) {
  .activityTable table {
    width: 800px;
  }
}
