.billingInfo {
  .caption {
    h3 {

      @include Montserrat(600);
      font-size: $font18;
      line-height: 150%;
      color: $gray-700;
      margin-bottom: 0
    }

    p {
      @include Montserrat(400);
      font-size: $font12;
      line-height: 150%;
      color: $gray-700;
    }
  }
}

.billingCard {
  padding: 16px 0;
  display: flex;
  align-items: center;
  max-width: 595px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.6);

  .icon {
    margin-right: 16px;
    width: 30px;

    img {
      width: 100%;
    }
  }

  .mediaBody {
    flex: 1;

    h4 {
      @include Montserrat(500);
      font-size: $font12;
      line-height: 150%;
      color: $gray-800;
      margin: 0;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        background: $border;
        @include border-radius(12px);
        @include Montserrat(500);
        font-size: $font12;
        color: $primary;
        text-decoration: none;
        margin-left: 16px;

        i {
          margin-right: 5px;
        }
      }
    }

    p {

      @include Montserrat(400);
      font-size: 11px;
      line-height: 150%;
      color: $gray-800;
      margin: 0
    }
  }

  a {
    @include Montserrat(500);
    font-size: $font14;
    line-height: 150%;
    text-align: right;
    text-decoration-line: underline;
    color: $gray-800;

    &.btn {
      text-decoration: none;
    }
  }
}

.invoiceCard {
  .controlBar {
    padding: 16px 0;
    background: transparent;
    box-shadow: none;
  }

  .ctaTable {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button {
      &.btn {
        min-width: 129px;
        height: 32px;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        margin-right: 10px;
        i{
            margin-right: 5px;
        }
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .billingCard .mediaBody h4 a{
    margin-left: 0;
}

}


@media (max-width:700px) {
  .invoiceCard table{
    width: 800px;
  }
}