@import "../../../mixins.scss";

.first-section {
  display: flex;
  flex-direction: unset;

  .devices_image {
    margin-top: 20px;
    @media (max-width: 1350px) {
      margin-top: 12;
      margin-left: auto;
      margin-right: auto;
    }
    @include ipad {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      img {
        width: 80%;
      }
    }

    img {
      max-width: 671px;
      @include not-max {
        max-width: 500px;
      }

      @include phone {
        max-height: 200px;
      }
    }

    > svg:first-child {
      max-width: 671px;
      margin-left: 60px;
      @include not-max {
        max-width: 500px;
      }

      @include phone {
        max-height: 200px;
        margin-left: 0;
        max-width: 320px;
      }
    }

    @include phone {
      width: 100%;
    }
  }
}

.liner {
  width: 100%;
  display: flex;
  border: 1px solid #e7e7e7;
}

.property_title {
  @include not {
    margin-left: 270px;
  }

  @include ipad {
    margin-left: unset !important;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 25px;
  }
  @include phone {
    padding-top: 0 !important;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-right: 0;

  @include ipad {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
    padding: 0 15px;
  }

  .property_liner {
    display: none;

    .website_content {
      width: 70%;
      @include not() {
        width: 100%;
      }
      @include phone() {
        width: 80%;
      }

      h1 {
        font-family: NunitoSans-Bold;
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 1rem;
        @include ipad {
          max-width: 193px;
          margin: 0 0 10px;
        }
        @include ipad() {
          font-size: 15px;
          line-height: 20px;
          max-width: unset;
        }
      }

      span {
        font-family: NunitoSans-Regular;
        font-style: normal;
        font-weight: normal;
        color: rgba(57, 53, 53, 1);
        font-size: 12px;
        line-height: 16px;
        @include phone {
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }

  .website_section {
    margin-bottom: 30px;
    @include ipad {
      margin-bottom: 15px;
    }

    > .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      @include ipad {
        margin-top: 0;
        justify-content: flex-start;
      }

      .btn_buton,
      .copied_btn {
        width: 150px;
        height: 40px;
        font-size: 12px;
        outline: none;
        box-sizing: border-box;
        font-family: NunitoSans-Regular;
        text-transform: uppercase;
        transition: 0.3s linear;
        cursor: pointer;

        @include ipad() {
          font-size: 12px;
          margin-top: 10px;
          height: 35px;
        }

        .liner_border {
          border-right: 1px solid #bfbfbf;
          width: 1px;
          height: 90%;
          margin: 0 10px;
        }

        &:nth-child(2) {
          background-color: $light-purple !important;
          color: #000000 !important;
          padding-left: 0 !important;
          margin-left: 20px !important;
          @include ipad {
            margin-left: 17px;
          }
          @include phone {
            margin-left: 10px;
          }

          &:hover {
            color: $light-purple !important;
            background-color: #000000 !important;
          }

          &::before {
            border: none;
            content: unset;
          }
        }

        &:nth-child(1) {
          margin-left: 15px;
          border-color: #000000;
          background-color: transparent;
          position: relative;
          padding-left: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @include ipad() {
            margin-left: 0;
          }

          &::before {
            content: "";
            position: absolute;
            background-image: url("../images/copy.svg");
            background-size: cover;
            width: 16px;
            height: 16px;
            left: 10px;
            top: 50%;
            transform: translateY(-60%);
            transition: 0.3s all linear;
          }

          @include not() {
            left: 10px;
          }
          @include ipad() {
            left: 10px;
          }
          @include phone() {
            left: 0;
          }
        }

        &:hover {
          background-color: #000000 !important;
          color: $light-purple !important;

          &::before {
            // background-image: url("../images/copyHover.svg");
          }

          .liner_border {
            border-color: #ffff;
          }
        }
      }
    }

    #custom-url-liner {
      @include ipad {
        margin-top: 20px !important;
      }
    }

    .copied_btn {
      border-radius: 5px;
      background-color: black !important;
      color: #fff;

      &:hover {
        color: #fff !important;
      }

      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/copiedURL.svg") !important;
        background-size: cover;
        width: 15px;
        height: 15px;
        left: 10px !important;
        top: 50%;
        transform: translateY(-60%);
      }
    }
  }

  .btn_buton_customize {
    width: 150 ox;
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    outline: none;
    font-family: NunitoSans-Regular;
    text-transform: uppercase;
    transition: 0.3s linear;
    cursor: pointer;

    @include ipad() {
      margin-top: 10px;
    }
    @include phone {
      height: 35px;
    }

    &:nth-child(2) {
      background-color: $light-purple !important;
      padding-left: 0 !important;
      margin-left: 20px !important;
      color: #000000 !important;

      &:hover {
        color: $light-purple !important;
        background-color: #000000 !important;
      }

      &::before {
        border: none;
        content: unset;
      }

      @include ipad {
        height: 35px;
      }
    }

    &:nth-child(1) {
      margin-left: 15px;
      box-sizing: border-box;
      background-color: transparent;
      position: relative;
      border-color: #000000;
      color: #000000;
      box-sizing: border-box;
      @include ipad {
        margin-left: 9px;
        height: 35px;
      }

      @include phone {
        margin-left: 0;
      }

      &:hover {
        background-color: #000000 !important;
        color: $light-purple !important;
        border: none;

        &::before {
          // background-image: url("../images/copyHover.svg");
        }

        .liner_border {
          border-color: #1c1c1c;
        }
      }
    }
  }

  > .btn_wrapper_custom_url {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    @include ipad {
      justify-content: flex-start;
      margin-top: 15px;
    }

    .copied_btn_custom_url {
      border-radius: 5px;
      background-color: black !important;
      color: #fff;

      &:hover {
        color: #fff !important;
      }

      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/copiedURL.svg") !important;
        background-size: cover;
        width: 15px;
        height: 15px;
        left: 12px !important;
        top: 50%;
        transform: translateY(-60%);
      }
    }

    .btn_buton_custon_url,
    .copied_btn_custom_url {
      width: 150px;
      height: 40px;
      font-size: 12px;
      outline: none;
      border: none;
      font-family: NunitoSans-Regular;
      text-transform: uppercase;
      transition: 0.3s linear;
      background-color: $light-purple !important;
      color: #000000;
      position: relative;
      padding-left: 25px;
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include ipad() {
        left: 10px;
      }

      .btn_buton_custon_url,
      .copied_btn_custom_url {
        width: 150px;
        height: 40px;
        font-size: 12px;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        text-transform: uppercase;
        transition: 0.3s linear;
        background-color: $light-purple !important;
        color: #000000;
        position: relative;
        padding-left: 25px;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include ipad() {
          left: 10px;
        }

        margin-left: 10px;
        line-height: 16px;

        &::before {
          content: "";
          position: absolute;
          background-image: url("../images/copy.svg");
          background-size: cover;
          width: 15px;
          height: 15px;
          left: 1.5rem;
          top: 50%;
          transform: translateY(-60%);
          transition: 0.3s all linear;

          @include not() {
            left: 10px;
          }
          @include ipad() {
            left: 10px;
          }
        }

        @include ipad() {
          height: 35px;
        }

        &:hover {
          color: $light-purple !important;
          background-color: #000000 !important;

          &::before {
            // background-image: url("../images/copyHover.svg");
          }

          .liner_border {
            border-color: #1c1c1c;
          }
        }

        .liner_border {
          border-right: 1px solid $dark-purple !important;
          width: 1px;
          height: 90%;
          margin: 0 10px;
        }
      }
    }

    .custom_url_button {
      position: relative;
      border: 0.7px solid rgba(0, 0, 0, 0.3);
      width: 61.9%;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 4.5rem;
      font-size: 12px;
      outline: none;
      font-family: NunitoSans-Regular;
      text-transform: uppercase;
      transition: 0.3s linear;
      cursor: pointer;
      line-height: 16px;

      .url-span {
        text-transform: lowercase;
        font-size: 12px;
        display: inline-block;
        width: fit-content;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        @include ipad {
          font-size: 12px;
          line-height: initial;
          padding-left: 6px;
        }
      }

      @include ipad {
        padding-left: unset;
        justify-content: center;
        font-size: 9px;
        width: 150px;
        height: 35px;
      }

      .liner_border {
        border-right: 1px solid #bfbfbf;
        width: 1px;
        height: 90%;
        margin: 0 10px;
        @include ipad {
          display: none;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background-image: url("../images/copy_url.svg");
        background-size: cover;
        width: 22px;
        height: 20px;
        left: 1.7rem;
        top: 0.8rem;
        @include ipad {
          background-image: unset;
          content: unset;
        }
      }
    }
  }

  .customUrl_wrapper {
    margin-left: 20px;
  }
}

.liner-first-section {
  width: 86.2%;
  display: flex;
  border: 1px solid #e7e7e7;
  position: relative;
  left: 3.5%;
  bottom: 30px !important;
  margin-bottom: 4rem;
  @media (max-width: 1450px) {
    bottom: 20px !important;
  }
  @include ipad {
    width: 100%;
    left: 0;
    bottom: 20px !important;
  }
  @include phone {
    bottom: unset !important;
  }
}

@media (max-width: 1350px) {
  .first-section {
    flex-direction: column;
  }

  .copied_btn {
    &:nth-child(1) {
      &::before {
        left: 10px;
        width: 13.94px !important;
        height: 14px !important;
      }
    }
  }

  .btn_buton {
    &:nth-child(2) {
      margin-left: 20px !important;
    }
  }

  .btn_buton_customize {
    &:nth-child(1) {
      margin-left: 10px !important;
      @include ipad {
        margin-left: 0 !important;
      }
    }
  }
}

.liner-first-section {
  bottom: unset;
}

@media (max-width: 600px) {
  .btn_buton {
    &:nth-child(2) {
      margin-left: 10px !important;
    }
  }

  .btn_buton_customize {
    &:nth-child(1) {
      margin-left: 0 !important;
    }
  }
}
