@import "../../mixins.scss";

.cover_section_video {
  object-fit: cover;
  width: 100%;
  max-height: 289px;
}

.logo-container {
  background: transparent;
  position: absolute;
  z-index: 99999999999;
  top: 22px;
  left: 40px;
}

.downloads_cover_section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(14, 17, 28, 0.8) 0%,
    rgba(14, 17, 28, 0) 100%
  );
  @include ipad {
    height: 112vh;
  }
  @include phone {
    width: unset;
  }

  .bg_image_download {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(14, 17, 28, 0.8) 27.53%,
      rgba(14, 17, 28, 0) 100%
    );
  }

  .title_box {
    position: absolute;
    width: 100%;
    top: 31rem;
    padding: 0 22px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fixed_header_wrapper {
      @include ipad {
        flex-direction: column;
        justify-content: center;
      }

      .header_title {
        display: flex;
        align-items: center;

        @include ipad {
          justify-content: center;
        }

        h2 {
          font-family: NunitoSans-Regular, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 2.5rem;
          line-height: 3.4rem;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: white;
          margin-bottom: 1.5rem;
          @media only screen and (max-width: 1023.8px) {
            font-size: 20px;
            line-height: 27px;
            font-family: NunitoSans-Bold, sans-serif;
            font-weight: 700;
            margin-bottom: 10px;
            text-align: center !important;
            margin-right: unset !important;
          }
        }
      }

      .header_subtitle {
        h3 {
          font-family: NunitoSans-SemiBold, sans-serif;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 700;
          font-size: 2.1rem;
          line-height: 2.9rem;
          color: white;
          margin-bottom: 5px;

          @media only screen and (max-width: 1023.8px) {
            font-size: 19px;
            line-height: 25px;
            font-family: NunitoSans-Bold, sans-serif;
            font-weight: 600;
            margin-bottom: 1rem;
            text-align: center;
          }
        }

        @include ipad {
          flex-direction: column;
          justify-content: unset;
        }
      }

      .header_icons {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1023.8px) {
          justify-content: center;
        }
      }
    }

    .close_section {
      display: none;
    }

    .download_icon {
      display: block;
      width: 3.9rem;
    }

    .play_icon {
      margin: 0 12px;
    }

    .liner_icon {
      display: block;
      width: 6rem;
      margin: 15px 0;
      @include ipad {
        width: 60px;
      }
    }

    .agent {
      font-family: NunitoSans-Regular, sans-serif;
      font-weight: normal;
      display: block;
      color: #ffffff;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      display: flex;

      p {
        font-family: NunitoSans-Bold, sans-serif;
        font-weight: normal;
        display: block;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        display: flex;
        margin: 0 5px 5px 0;
        @media only screen and (max-width: 1023.8px) {
          font-size: 16px;
          line-height: 22px;
          font-family: NunitoSans-Bold, sans-serif;
          font-weight: 700;
        }
      }

      @media only screen and (max-width: 1023.8px) {
        font-size: 16px;
        line-height: 22px;
        font-family: NunitoSans-Bold, sans-serif;
        font-weight: 600;
        justify-content: center;
      }
    }

    .date_shot {
      font-family: NunitoSans-Regular, sans-serif;
      font-weight: normal;
      display: block;
      color: #ffffff;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      display: flex;
      margin-bottom: 10px;

      p {
        font-family: NunitoSans-Bold, sans-serif;
        font-weight: normal;
        display: block;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        display: flex;
        margin: 0 5px 0 0;
        @media only screen and (max-width: 1023.8px) {
          font-size: 14px;
          line-height: 19px;
          font-family: NunitoSans-Bold, sans-serif;
          font-weight: 700;
        }
      }

      @media only screen and (max-width: 1023.8px) {
        font-size: 14px;
        line-height: 19px;
        font-family: NunitoSans-Bold, sans-serif;
        font-weight: 600;
        justify-content: center;
      }
    }

    .svg_section {
      img {
        height: 2.5rem;
        margin-right: 10px;
        filter: none;
        margin: 1rem;
        @include ipad {
          height: 20px;
        }
      }

      @include ipad {
        margin-top: 5px;
      }
    }
  }

  .title_box_hide {
    display: none !important;
    position: fixed;
    width: 100%;
    top: 6.6rem;
    padding: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    height: 66px;
    z-index: 998;
    padding: 16px 40px;

    @include ipad {
      position: absolute;
      width: 60%;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 250px;
      flex-direction: column;
      justify-content: center;
      padding: 25px 33px;
    }

    @include phone {
      top: 65%;
      width: 70%;
    }

    @include small {
      width: 80%;
    }

    .fixed_header_wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include ipad {
        flex-direction: column;
        justify-content: center;
      }

      .header_title {
        width: 30%;
        display: flex;
        align-items: center;

        @include ipad {
          width: 100%;
          justify-content: center;
        }

        h2 {
          font-family: NunitoSans-SemiBold, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 2.5rem;
          line-height: 3.4rem;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #1a1a1a;
          @media only screen and (max-width: 1023.8px) {
            font-size: 20px;
            line-height: 27px;
            font-family: NunitoSans-Bold, sans-serif;
            font-weight: 700;
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }

      .header_subtitle {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          white-space: nowrap;
          font-family: NunitoSans-SemiBold, sans-serif;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          font-style: normal;
          font-weight: bold;
          font-size: 2.1rem;
          line-height: 2.9rem;
          color: #000000;
          @include not-max {
            text-align: center;
          }

          @include ipad {
            margin-bottom: 10px;
          }
          @media only screen and (max-width: 1023.8px) {
            white-space: unset;
            font-size: 16px;
            line-height: 22px;
            font-family: NunitoSans-Bold, sans-serif;
            font-weight: 600;
          }
        }

        span {
          white-space: nowrap;
        }

        @include ipad {
          width: 100%;
          flex-direction: column;
          justify-content: unset;
        }

        .agent {
          font-family: NunitoSans-Regular, sans-serif;
          font-weight: normal;
          display: block;
          color: #000000;
          font-size: 1.4rem;
          line-height: 1.9rem;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          display: flex;
          margin-bottom: 7px;

          p {
            font-family: NunitoSans-Regular, sans-serif;
            font-weight: normal;
            display: block;
            color: #000000;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.9rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            display: flex;
            margin: 0 5px 0 0;
            @media only screen and (max-width: 1023.8px) {
              font-size: 16px;
              line-height: 22px;
              font-family: NunitoSans-Bold, sans-serif;
              font-weight: 700;
            }
          }

          @media only screen and (max-width: 1023.8px) {
            font-size: 16px;
            line-height: 22px;
            font-family: NunitoSans-Regular, sans-serif;
            font-weight: 600;
            margin-bottom: 10px;
          }
        }

        .date_shot {
          font-family: NunitoSans-Regular, sans-serif;
          font-weight: normal;
          display: block;
          color: #000000;
          font-size: 1.4rem;
          line-height: 1.9rem;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          display: flex;

          p {
            font-family: NunitoSans-Regular, sans-serif;
            font-weight: normal;
            display: block;
            color: #000000;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.9rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            display: flex;
            margin: 0 5px 0 0;
            @media only screen and (max-width: 1023.8px) {
              font-size: 14px;
              line-height: 19px;
              font-family: NunitoSans-Bold, sans-serif;
              font-weight: 700;
            }
          }

          @media only screen and (max-width: 1023.8px) {
            font-size: 14px;
            line-height: 19px;
            font-family: NunitoSans-Regular, sans-serif;
            font-weight: 600;
          }
        }
      }

      .header_icons {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include ipad {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .close_section {
      display: none;
      @include ipad {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .download_icon {
      display: none;
    }

    .svg_section {
      position: absolute;
      right: 40px;

      @include ipad {
        margin-top: 5px;
        position: relative;
        right: unset;
      }

      img {
        height: 2.5rem;
        margin-left: 10px;
        @include ipad {
          height: 20px;
        }
      }
    }

    .liner_icon {
      display: none;
    }

    .six_liner path:last-child {
      fill: #000000;
    }

    .play_icon {
      margin: 0 12px;
    }

    .play_icon path:not(:nth-child(0)) {
      fill: #000000;
    }

    .el_icon path {
      fill: #000000;
    }

    .text_section {
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include ipad {
        margin-left: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
  }

  .mouse_section {
    position: absolute;
    width: 100%;
    max-width: 144.4rem;
    padding: 0 22px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    bottom: 7rem;
    @include ipad {
      bottom: 11rem;
    }
    @include phone {
      max-width: unset !important;
    }

    .scroll_text {
      font-family: "NunitoSans-Light", sans-serif;
      font-size: 1.4rem;
      line-height: 1.9rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: 10px;

      @media only screen and (max-width: 1023.8px) {
        font-size: 11px;
        line-height: 15px;
      }

      @include phone {
        margin-left: unset !important;
      }
    }

    .mouse_scroll {
      display: flex;
      justify-content: center;

      img {
        width: 3.5rem;
      }
    }

    .mouse_scroll::before {
      animation: indicateScroll 2s cubic-bezier(0.7, 0, 0.3, 1) 0s infinite;
      content: url("../../icons/mouse_scroll.svg");
      position: absolute;
      top: 5px;
      opacity: 0;
      @media only screen and (max-width: 1023.8px) {
        animation: unset;
        content: unset;
      }
    }

    @keyframes indicateScroll {
      from {
        top: 5px;
        opacity: 1;
      }
      to {
        top: 25px;
        opacity: 0;
        @include not {
          top: 20px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .downloads_cover_section {
    .title_box {
      top: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > h2 {
        text-align: center;
        margin-right: unset !important;
      }

      h3 {
        text-align: center;
      }

      .download_icon {
        width: 30px;
      }

      .svg_section {
        img {
          height: 2.5rem;
          margin-right: 10px;
          @include ipad {
            height: 20px;
          }
        }
      }

      span {
        text-align: center;
      }
    }

    .mouse_section {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20%;

      .mouse {
        display: none;
      }
    }
  }
}

.scrolled.title_box {
  display: none;
}

.download_page_information_title_box {
  top: 325px !important;
  padding-left: 260px;

  @include phone {
    top: 290px !important;
  }
}
