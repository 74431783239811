.popup_container {
  width: 160rem;
  background-color: #fff;
  position: fixed;
  left: 10%;
  top: 10%;
  z-index: 999;
  padding-bottom: 5rem;

  .popup_wrapper {
    margin: 10rem;

    .popup_close {
      display: block;
      position: absolute;
      right: 5rem;
      top: 5rem;
    }

    .popup_title_box {
      font-size: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .title_text {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: center;
      }

      .agent {
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
        text-align: center;

        p {
          font-weight: bold;
        }
      }

      .date_shot {
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
        text-align: center;

        p {
          font-weight: bold;
        }
      }

      .svg_section {
        margin-top: 5rem;

        > img {
          margin: 1rem;
        }
      }
    }
  }


}