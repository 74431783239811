@import "../../mixins.scss";

.main-content {
  display: flex;
  flex-direction: column;
  padding-left: calc(1px + 58px);
  padding-right: 17.5rem;

  @include phone {
    width: 99%;
  }

  .info {
    .main-info {
      display: flex;
      flex-direction: inherit;
      align-items: baseline;

      .title {
        color: rgba(0, 0, 0, 1);
        font-family: "NunitoSans-SemiBold";
        font-style: normal;
        font-size: 2.5rem;
        line-height: 3.4rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 1.2rem;
        margin-top: 4rem;
        padding-right: 3.2rem;
      }
    }

    .subtitles_virtual_staging {
      .address {
        display: none;
      }

      .quantity {
        font-family: NunitoSans-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: rgba(65, 65, 65, 1);
      }
    }
  }

  .before_checkbox_section {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .before_checkbox_section input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    right: 10px;
    height: 17px;
    width: 17px;
    border-radius: 2px;
    background-color: transparent;
    border: 1.2px solid #ffffff;
    @include ipad {
      height: 13px !important;
      width: 13px !important;
    }
  }

  .before_checkbox_section input:checked + .checkmark {
    background-size: 60%;
    content: "";
    background-image: url("../download-page-sections/feature-sheet/elements/checkmark.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-color: #ffffff;
  }

  .virtual-staging-grid-container {
    .scrollbar {
      display: grid;
      grid-template-columns: repeat(2, 26rem);
      grid-column-gap: 0rem;
      margin-top: 4rem;
      width: fit-content;
      grid-row-gap: 2.4rem;
      overflow: overlay;
      scroll-behavior: auto;
      max-height: 18.5rem;
      overflow-x: hidden;
      margin-bottom: 25px;

      @include phone {
        grid-template-columns: repeat(2, 26rem);
        overflow-x: unset;
        margin-bottom: unset;
      }

      img {
        position: relative;
        width: 26.4rem;

        border-radius: 5px;
      }
    }

    .force-overflow {
      min-height: 450px;
    }

    .scrollbar-default::-webkit-scrollbar {
      width: 4px;
      border-radius: 5px;
      background-color: #e6e6e6;
    }

    .scrollbar-default::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #bbbaba;
      background-color: #bbbaba;
    }

    .staging-items {
      position: relative;
      width: fit-content;

      .hover-section {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0px;
        bottom: 0;
        border-radius: 5px;
        margin-left: 0rem;
        margin-top: 0rem;
        margin-bottom: 0rem;
        right: 0;

        .checkmark_checkbox {
          position: absolute;
          z-index: 99;
        }

        .hide_checkbox {
          display: none;
        }

        .show_checkbox {
          display: block;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
          background-image: url("../virtual-staging/svg/hover.svg");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 5px;

          img {
            display: none;
          }

          .hide_checkbox {
            display: block;

            @include phone {
              display: none;
            }
          }
        }

        img {
          display: none;
          position: absolute;
          z-index: 99;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 30px !important;
          height: 30px !important;
        }

        .before_checkbox_section {
          position: absolute;
          z-index: 99;
          right: 0px;
          bottom: 15px;

          @media (max-width: 911px) {
            bottom: 5px;
          }
        }
      }
    }
  }

  .gallery_button_section {
    button {
      width: 150px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
      outline: none;
      border: none;
      font-family: NunitoSans-Regular;
      text-transform: uppercase;
      transition: 0.3s linear;
      cursor: pointer;
    }

    .cancel {
      margin-left: 15px;
      border: 1px solid #000000;
      background-color: transparent;
      position: relative;
      border-radius: 5px;
      color: #000000;

      &:hover {
        background-color: #000000;
        color: #ffffff;
      }
    }

    .select {
      display: block;
      background-color: #000000;
      padding-left: 0 !important;
      margin-left: 15px;
      color: #ffffff;
      border-radius: 5px;

      &:hover {
        color: black !important;
      }
    }

    .download_btn_wrapper {
      &:hover {
        .dowload_section {
          display: block;
        }

        .download_btn {
          color: $light-purple !important;
          background-color: #000000;
          border-radius: 5px;
        }

        .download_virtual_staging_btn {
          color: $light-purple !important;
          background-color: #000000;
          border-radius: 5px;
        }

        .download_btn::before {
          content: url("../download-page-sections//images/arrowUp.svg");
          position: absolute;
          width: 1.5rem;
          right: 12px;
        }
      }

      .download_btn {
        width: 150px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        transition: 0.3s linear;
        cursor: pointer;
        position: relative;
        background-color: $light-purple !important;
        color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        display: flex;
        justify-content: flex-end;
        padding-right: 21px;
        align-items: center;

        .liner_border {
          border-right: 1px solid $dark-purple;
          width: 1px;
          height: 80%;
          margin: 0 12px 0 20px;
        }

        &::before {
          content: url("../download-page-sections//images/arrowDown.svg");
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 1.5rem;
        }
      }

      .download_virtual_staging_btn {
        width: 150px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        transition: 0.3s linear;
        cursor: pointer;
        position: relative;
        background-color: $light-purple !important;
        color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .liner_border {
          border-right: 1px solid $dark-purple;
          width: 1px;
          height: 80%;
          margin: 0 12px 0 20px;
        }
      }

      .download_virtual_staging_btn.download_virtual_staging_btn_disable {
        background-color: #cccccc;

        &:hover {
          background-color: #cccccc;
          color: #000000;
          cursor: not-allowed;
        }
      }
    }

    .dowload_section {
      position: absolute;
      margin-left: 15px;
      width: 150px;
      display: flex;
      background: #ffffff;
      display: none;
      border-radius: 0px 0px 5px 5px;

      .download_options {
        display: flex;
        flex-direction: column;

        span {
          font-family: NunitoSans-Regular;
          font-style: normal;
          font-size: 11px;
          line-height: 1.5rem;
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background: #ebebeb;
            border-radius: 0px 0px 5px 5px;
          }
        }
      }
    }

    .liner_border {
      border-right: 1px solid $dark-purple;
      width: 1px;
      height: 80%;
      margin: 0 1.5rem 0 2rem;
    }

    margin-bottom: 15px;

    .btn-wrapper {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      padding-top: 2rem;
    }
  }

  .hide {
    display: none;
  }

  .select_item {
    display: flex;
    justify-content: flex-end;
    font-family: NunitoSans-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
    color: #414141;
    position: absolute;
    right: 17.6rem;
    @include phone {
      right: unset;
    }
  }
}

#matterport_line {
  margin-top: 40px !important;
}

@media (max-width: 1500px) {
  .main-content {
    padding-left: calc(1px + 90px);

    .virtual-staging-grid-container {
      .scrollbar {
        height: 19.5rem;
        margin-left: -6rem;
        margin-bottom: 25px;
        margin-right: -1rem;

        img {
          position: relative;
          padding-left: 0rem;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .main-content {
    padding-left: calc(1px + 60px);

    .virtual-staging-grid-container {
      .scrollbar {
        margin-left: unset;
      }
    }
  }
}

@media (max-width: 1380px) {
  .main-content .virtual-staging-grid-container {
    .scrollbar {
      margin-left: 0rem;
    }
  }
}

@media (max-width: 1058px) {
  .main-content .virtual-staging-grid-container.staging-items .hover-section {
    top: 12px;
    left: 13px;

    .checkbox_item {
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: 1034px) {
  .main-content {
    padding-right: 129px;
    padding-left: 48rem;
  }

  .virtual-staging-grid-container {
    height: 22rem;
    margin-top: 4rem;
    padding-left: 20rem;
  }
}

@media (min-width: 1024px) and (max-width: 1379.8px) {
  .main-content {
    padding-left: 70px;
    padding-right: 17rem;
  }
}

@media (max-width: 1020px) {
  .main-content {
    padding-right: 4px;
    padding-left: 12rem;

    .main-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        margin-top: 20px;
        margin-bottom: 5px;
        padding-right: 0;
        font-size: 18px !important;
        line-height: 25px;
      }
    }

    .delivery_update_button {
      padding-bottom: 6px;
      padding-top: 10px;
    }

    .subtitles_virtual_staging {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .virtual-staging-address-holder {
        padding-bottom: 3px;

        .address {
          display: block;
        }
      }

      .quantity {
        display: flex;
        justify-content: center;
      }
    }

    .virtual-staging-grid-container {
      .scrollbar {
        grid-template-columns: auto auto auto;
      }
    }

    .image {
      width: auto;
      height: auto;
    }

    .gallery_button_section {
      .btn-wrapper {
        display: flex;
        justify-content: flex-start;
        padding-left: 6px;

        button,
        .download_btn {
          display: flex;
          margin-top: 10px;
          width: 150px;
          height: 35px;
          font-size: 12px;
          line-height: 16px;
          justify-content: center;
          align-items: center;
        }

        .download_virtual_staging_btn {
          display: flex;
          margin-top: 10px;
          width: 150px;
          height: 35px;
          font-size: 12px;
          line-height: 16px;
          justify-content: center;
          align-items: center;
        }

        .download_btn {
          justify-content: flex-end;

          .liner_border {
            margin: 0 26px 0 15px;
          }
        }

        .download_virtual_staging_btn {
          .liner_border {
            margin: 0 26px 0 15px;
          }
        }

        .download_btn::before {
          right: 17px !important;
        }

        span {
          padding: 10px !important;
        }

        .download {
          padding-right: 10.1rem;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .main-content {
    padding-right: 0px;
    padding-left: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .select_item {
      display: flex;
      justify-content: flex-start;
      font-size: 10px;
      padding-left: 4rem;
    }

    .info {
      .main-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          padding-right: 0;
          margin-bottom: -8px;
          font-size: 18px !important;
          line-height: 25px;
          margin-top: 0;
        }
      }
    }

    .virtual-staging-grid-container {
      padding-top: 10px;
      margin-top: unset;

      .scrollbar {
        grid-template-columns: auto, auto, auto;

        .image {
          width: 100%;
          height: 100%;
        }
      }
    }

    .quantity {
      display: flex;
      justify-content: center;
      font-size: 10px;
      line-height: 4rem;
    }

    .download_btn {
      padding-right: 0px !important;
    }

    .download_virtual_staging_btn {
      padding-right: 0px !important;
    }

    .virtual-staging-grid-container {
      padding-left: unset;
      height: unset;

      .scrollbar {
        max-height: unset;
        height: unset;
        grid-column-gap: 2px;
        grid-row-gap: 2px;
      }
    }

    .liner_border {
      margin: 0 -1.5rem 0 2rem;
    }
  }
}

@media (max-width: 450px) {
  .main-content {
    .quantity {
      font-size: 10px !important;
      line-height: 11rem !important;
    }

    .grid-container {
      .scrollbar {
        margin-left: -1rem;
        margin-right: -4rem;
      }
    }

    .all_content {
      padding-left: 0;
      padding-right: 0;
    }

    .gallery_button_section {
      .btn-wrapper {
        .select {
          display: none;
        }

        .download_btn {
          padding-right: 0px !important;
        }

        .download_virtual_staging_btn {
          padding-right: 0px !important;
        }

        .cancel {
          margin-left: 9px;
        }
      }
    }

    .select_item {
      justify-content: flex-start;
      padding-left: 7rem;
    }
  }
}

.download_virtual_staging_btn_opacity {
  opacity: 0.6;

  &:hover {
    background-color: $light-purple !important;
    color: #000000 !important;
    cursor: not-allowed;
  }
}

.main-content
  .virtual-staging-grid-container
  .staging-items
  .hover-section:hover {
  @include phone {
    display: none;
  }
}
