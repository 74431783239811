.popup {
    position: absolute;
    top: 67px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    z-index: 9;
    .content {
        padding: 20px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .popup-icon {
            position: absolute;
            left: 20px;
            top: -8px;
        }
        & > div {
            width: 100%;
        }
        & > div:last-child {
            margin-bottom: 0;
        }
    }
}