@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  min-height: 100vh;
  @include Montserrat(400);
  background: $bodyBg;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray200;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray;
}

.wrapper {
  overflow: hidden;
}

.places-dropdown-container {
  border: 1px solid $primary;
  border-radius: 3px;
  display: flex;
  column-gap: 10px;
  flex-direction: column;
  font-size: $font14;
  position: absolute;
  width: 95%;
  z-index: 99;

  > div {
    cursor: pointer;
    &:hover {
      background-color: $primary !important;
    }
  }

  .address-item {
    padding: 10px 15px;
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
  }

  .address-item.parent:hover {
    background: $primary !important;
  }
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.readOnly-input {
  border: none;
  outline: none;
}

.main {
  display: flex;

  .content {
    flex: 1;
    width: calc(100% - 240px);
  }
}

h2 {
  @include Montserrat(600);
  font-size: $font28;
  line-height: 120%;
  color: $gray-800;
}

h4 {
  @include Montserrat(600);
  font-size: $font14;
  line-height: 150%;
  color: $gray-800;
  margin-bottom: 8px;
}

label {
  font-weight: 500;
  font-size: $font14;
  line-height: 150%;
  color: $gray-800;
  margin-bottom: 8px;
}

.dashboardMain {
  padding: 16px 36px;
  border-radius: 12px !important;
}

.dashboardContent {
  padding: 16px 36px;
}

.heading3 {
  @include Montserrat(600);
  font-size: $font24;
  line-height: 150%;
  color: $primary;
  margin-bottom: 16px;
}

.heading4 {
  @include Montserrat(600);
  font-size: $font18;
  line-height: 150%;
  color: $primary;
  margin-bottom: 16px;
}

.badges {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include Montserrat(500);
  font-size: $font12;
  line-height: 100%;
  padding: 5px 10px;
  @include border-radius(12px);
  text-transform: capitalize;

  &.selectable-badge {
    cursor: pointer;
  }

  &.badge-danger,
  &.badge-pending {
    color: $danger;
    border: 1px solid $danger;
  }
  &.badge-danger-fill,
  &.badge-requested {
    background: $danger;
    color: $white;
    border: 1px solid $danger;
  }
  &.badge-success,
  &.badge-completed {
    color: $white;
    background: $success;
    border: 1px solid $success;
  }
  &.badge-success-fill,
  &.badge-delivered {
    background: $success;
    color: $white;
    border: 1px solid $success;
  }

  &.badge-warning,
  &.badge-uploaded {
    color: $warning;
    background: $white;
    border: 1px solid $warning;
  }
  &.badge-warning-fill,
  &.badge-edited,
  &.badge-scheduled {
    background: $warning;
    color: $white;
    border: 1px solid $warning;
  }
}

.close-icon-container {
  cursor: pointer;
}

.status {
  display: flex;
  align-items: center;

  li {
    margin-left: 24px;
  }
}

.popover {
  h4,
  p {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.error-text {
  position: absolute;
  color: red;
  font-size: 12px;
}

.relative {
  position: relative;
}

.min-width-260 {
  min-width: 260px;
}

.react_tooltip {
  opacity: 1 !important;
  padding: 10px !important;
  gap: 10px !important;

  background: #f8f8f8 !important;
  border-radius: 8px !important;
  @include Montserrat(500);
  font-size: 10px !important;
  line-height: 150% !important;

  color: #000000 !important;
}

.ml-2 {
  margin-left: 20px;
}

.react_tooltip:after {
  border-right-color: #f8f8f8 !important;
}

.disabled-link {
  opacity: 0.3 !important;
  pointer-events: none;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

@include media-breakpoint-down(xl) {
  .dashboardContent,
  .dashboardMain {
    padding: 16px;
  }
}

@include media-breakpoint-down(md) {
  .dashboardMain {
    height: auto;
  }
}

@include media-breakpoint-down(sm) {
  .heading3 {
    font-size: 22px;
  }
}
