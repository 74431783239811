@import "../../../mixins.scss";

hr {
  display: block;
  width: 28rem !important;
  position: relative;
  top: 3.8rem;
  z-index: 2;
  color: #fff !important;
  border-top: 1px solid #fff !important;
}

.delivered-button {
  z-index: 18;
  position: relative;
  bottom: 35px;
  display: flex;
  justify-content: center;
  background: none;
  padding-top: 49px;
}

.wrapper_left_sidebar,
.js-is-sticky {
  width: 30.3rem;
  border-radius: 5px;
  z-index: 88;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 45px;
  transition: top 0.3s linear;
  @include ipad() {
    height: 133px;
    width: 100%;
    margin-top: unset;
    bottom: 0;
  }
  @include phone() {
    top: 55px;
  }

  .nextAdd,
  .prevAdd {
    position: absolute;
    left: 0;
    height: 2.8rem;
    width: 100%;
    outline: none;
    border: none;
    z-index: 3;
    background-color: #ffffff;
    transform-origin: top;
    transition: 0.1s linear, 0.3s ease transform;
    cursor: pointer;
    @include ipad {
      width: calc(100% - 20px);
      height: 28px;
      display: none;
    }

    &:hover {
      background-color: #e7e7e7;
    }

    &.swiper-button-disabled {
      &::before {
        opacity: 0.5;
      }
    }

    &::before {
      position: absolute;
      left: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      height: 0% !important;
      background-color: #e7e7e7;
      height: 1px;
      transform: translateX(-50%);
      @include ipad {
        width: 80px;
      }
    }

    .nextAdd::after:first-child(2) {
      display: none;
    }

    &:first-of-type {
      top: 0;

      &::before {
        top: 0;
        transform: translateX(-50%);
      }

      &::after {
        bottom: 0;
      }
    }

    &:last-of-type {
      bottom: 0;

      &::before {
        transform: rotate(180deg) translateX(50%);
        bottom: 0;
      }

      &::after {
        top: 0;
      }
    }
  }

  .sidebarOfDownloadZone {
    position: relative;
    bottom: calc(6.5rem + 20px);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;
    width: 100%;
    background-color: #ffffff;
    min-height: 100vh;
    margin-bottom: -2rem;
    margin-top: calc(2rem + 5px);
    box-shadow: 0px 3px 13px 0px rgba(130, 130, 130, 0.25);
    transform: translate3d(0px, 0, 0) !important;
    @media only screen and (max-width: 1800px) {
      margin-top: calc(2rem + 2px);
    }
    @media only screen and (max-width: 1500px) {
      margin-top: calc(2rem + -4px);
    }
    @media only screen and (max-width: 1250px) {
      margin-top: calc(2rem + -7px);
    }
    @media only screen and (max-width: 1050px) {
      margin-top: calc(2rem + -15px);
    }
    @include ipad {
      bottom: 70px;
    }
    @include ipad-min {
      transform: unset !important;
    }
    @include phone {
      bottom: 62px;
    }

    @include ipad {
      border-radius: 0;
      margin: unset;
      padding: 5px 0px 0px;
      display: flex;
      background-color: #ffffff;
      height: 128px;
      box-shadow: unset;
      transform: translate3d(0, 0, 0);
      display: flex;
      justify-content: center;
      min-height: unset;
      overflow-y: hidden;
      box-shadow: 0px -10px 36px rgba(0, 0, 0, 0.25);
    }
    @include phone {
      height: 111px;

      transform: translate3d(50px, 0, 0);
      justify-content: unset;
      background-color: #ffffff !important;
    }

    > .item {
      padding: 1.3rem 3.2rem 0;
      width: 100%;
      display: flex;
      height: 7.5rem;
      align-items: center;
      flex-direction: row;
      text-align: center;
      position: relative;
      cursor: pointer;

      @include ipad {
        height: 121px;
        padding: unset;
        width: 85px;
        background-color: #ffffff !important;
        flex-direction: column;
      }

      @include phone {
        height: 106px;
        margin: 0 -1px;
      }
      @include small {
        margin: 0 -1px;
        flex-direction: column;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 1px;
        background-color: #e7e7e7;
        transform: translateX(-50%);
        @include ipad {
          width: 80px;
          content: none;
        }
      }

      &:hover,
      &.active {
        background-color: #f4f4f4;
        z-index: 2;
        @include ipad {
          background-color: #efefef !important;
          .imgs {
            background: $dark-purple;
            border: unset;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 50px;
              padding: 1px;
              background: linear-gradient(
                180deg,
                $light-purple !important,
                0%,
                #ff6f41 100%
              );
              -webkit-mask: linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
            }
          }
        }
      }

      &.active {
        &::after {
          content: "";
          position: absolute;
          right: -5rem;
          top: 0;
          width: 1rem !important;
          background-color: #f4f4f4;
          @include ipad {
            content: "";
            background: white;
            z-index: 1;
            width: 10px;
            height: 10px;
            position: absolute;
          }
        }

        &::before {
          content: "";
          top: unset;
          left: 0;
          bottom: -1px;
          width: 100%;
          transform: unset;
          background-color: transparent;

          @include ipad {
            content: url("../left-sidebar/icons/rounded_active_bottom.svg");
            top: unset;
            left: -10px;
            bottom: 20px;
            width: 100%;
            height: 2px;
            transform: unset;
          }

          @media (max-width: 530px) {
          }
        }
      }

      &:hover {
        &:not(.active)::before {
          top: -1px;
        }
      }

      &.active {
        .active_arrow {
          opacity: 1;
        }
      }

      .active_arrow {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.5rem;
        height: 100%;
        opacity: 0;
        background-color: $light-purple;
        transition: 0.5s linear opacity;
        z-index: 4;
        @include ipad {
          display: none;
        }
      }

      > .imgs {
        margin-top: 1rem;
        height: initial;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5rem;
        margin-right: 4rem;

        @include ipad {
          height: 60px;
          width: 60px;
          background-color: #f4f4f4;
          border-radius: 50%;
          border: 1px solid;
          border-color: #cccccc;
          margin-bottom: 10px;
          margin-left: unset;
          margin-right: unset;
          margin-top: 20px;
        }

        @include phone {
          margin-left: 28px;
          margin-right: 7px;
          height: 50px;
          width: 50px;
          transform: translateX(-10px);
        }

        > img {
          margin-top: 0rem;
          margin-bottom: 2rem;
          max-width: 3.5rem;
          object-fit: contain;
          transition: 0.3s linear transform;
          @include ipad() {
            height: 25px;
            max-width: none;
            margin-bottom: 0;
          }
        }
      }

      .text {
        position: absolute;
        width: auto;
        height: 100%;
        top: 0.2rem;
        left: 13rem;
        display: flex;
        align-items: center;
        width: 40%;

        @include ipad {
          height: 32px;
          top: 67px;
          position: static;
          top: 43px;
          height: 24px;
          overflow: hidden;
        }

        @include ipad {
          width: 100%;
        }

        > span:not(.active_arrow) {
          left: 68%;
          position: absolute;
          color: #1a1a1a;
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 1.6rem;
          max-height: 3.2rem;
          transform: translateX(-5%) translateY(0px);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: "NunitoSans-SemiBold";
          margin-top: 0px;
          width: inherit;
          text-align: initial;
          display: inline-block;
          left: 10px;
          width: 100%;

          @include ipad {
            margin-top: 0;
            font-size: 12px;
            line-height: 16px;
            max-height: 32px;
            transform: translateX(-47%) translateY(0px);
          }
          @include ipad() {
            left: 40px;
            margin-top: 0;
            font-size: 9px;
            line-height: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            width: auto;
            text-align: center;
          }
          @include phone {
            left: 40px;
            bottom: 5px;
            width: 85%;
            display: inline-block;
            height: inherit;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .sidebarOfDownloadZone,
  .scrollbar {
    transform: translate3d(0px, 0, 0) !important;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 0.5px;
      width: 4px;
      border: none;
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  .delivered-button {
    .div-for-btn {
      display: none;
    }
  }
}

.left_sidebar_updated_section {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f33c3c;
  position: absolute;
  top: -4px;
  right: -4px;
}

.item.left_sidebar_not_active_section {
  opacity: 0.4;
  cursor: none;
}
