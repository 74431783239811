@import "../../mixins.scss";

.section_faq {
  background: #ffffff;
  margin-top: -3rem;
  margin-bottom: 8rem;
  display: flex;
  flex-wrap: wrap;
  display: none; // temporary
  @include ipad {
    margin-top: -30px;
    margin-bottom: 80px;
    flex-direction: column;
  }
  .grid_box {
    margin: 0 auto;
    max-width: 122rem;
    padding: 0 1rem;
    @include ipad {
      padding: 0 20px;
      max-width: unset;
      grid-gap: 10px 20px;
    }
    @include phone {
      margin-top: -25px;
      grid-template-columns: 1fr;
      grid-gap: 7px;
      padding: 0 15px;
    }
  }

  .grid-second-box {
    margin: 0 auto;
    max-width: 122rem;
    padding: 0 1rem;
    .second-box {
      margin-bottom: 10px;
    }
    @include ipad {
      padding: 0 20px;
      max-width: unset;
      grid-gap: 10px 13px;
    }
    @include phone {
      grid-gap: 7px;
      padding: 0 15px;
    }
  }
  .box,
  .second-box {
    margin-bottom: 10px;
    width: 59rem;
    height: auto;
    background-color: #ffffff;
    border: 1px solid rgba(235, 235, 235, 1);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ebebeb;
    overflow: hidden;
    transition: all ease 0.3s;

    @include ipad {
      width: auto;
    }

    &.active {
      box-shadow: 0px 0px 25px rgba(130, 130, 130, 0.2);
    }

    .title_wrapp {
      padding: 2.2rem 5rem 2.2rem 1.5rem;
      transition: 0.4s ease all;
      border-left: 5px solid $light-purple !important;

      @include ipad {
        min-height: 59px;
        padding-right: 30px;
        display: flex;
        align-items: center;
      }
      @include phone {
        min-height: 53px;
        padding-right: 30px;
      }
      &.show {
        height: max-content;
        background-color: $light-purple !important;

        .title {
          @include lineClamp(8);
        }
      }
    }

    .title {
      font-style: normal;
      line-height: 16px;
      font-family: "NunitoSans-Bold", sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin: 0 !important;
      color: #1a1a1a;

      @include ipad {
        font-size: 16px;
        -webkit-line-clamp: 2;
        line-height: 16px;
      }
      @include phone {
        font-size: 12px;
        line-height: 16px;
        padding-left: 10px;
      }
    }
    .description {
      font-family: NunitoSans-Regular, sans-serif;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      color: #414141;
      padding: 0 5rem 0 2rem;
      margin: 0 !important;
      overflow: hidden;
      max-height: 0;
      transition: all 0.2s ease;
      @include ipad {
        padding: 0 50px 0 20px;
        font-size: 16px;
      }
      @include phone {
        font-size: 12px;
        line-height: 16px;
      }

      > div {
        .grid-item {
          padding-top: 2rem;

          @include ipad {
            padding-top: 20px;
          }
          .videos {
            position: relative;
            width: 100%;
            overflow: hidden;
            padding-top: 56.25%;
            .video {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              border: transparent;
              border-radius: 5px;
            }
          }
        }
        .download_btn_wrapper_brand {
          display: none;
        }

        > div {
          .multi-liner {
            display: none;
          }
        }
      }

      &.show {
        max-height: 400px;
        padding: 2rem 3.6rem 1rem 2rem;
        overflow: auto;
        margin-bottom: 1.6rem;
        @include ipad {
          max-height: 500px;
          padding: 20px 50px 10px 20px;

          @include phone {
            max-height: 400px;
            padding: 20px 14px 10px 20px;
          }
        }
      }
    }

    .icons_wrapp {
      position: absolute;
      right: 2.6rem;
      top: 2.2rem;
      cursor: pointer;
      @include ipad {
        right: 15px;
        top: 18px;
        width: 23px;
        height: 23px;
      }
      @include phone {
        top: 20px;
        width: 16px;
        height: 16px;
        right: 12px;
      }
    }
    > button {
      outline: none;
      border: none;
      background: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.6rem;
      @include ipad {
        height: 16px;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        @include ipad {
          width: 15px;
          height: 15px;
        }
        & path {
          fill: #ffffff;
        }
      }
    }
  }
}
