.accordion-item {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 23px;

  .accordion-header {
    .accordion-button {
      border-radius: 5px 5px 0 0;
      background: #fff;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-transform: capitalize;
      color: #07364B;
      padding: 21px;
      border-left: 5px solid #07364B;

      &:focus {
        box-shadow: none;
      }

      &[aria-expanded="true"] {
        background: #07364B;
        color: #fff;

        &:after {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .accordion-body {
    padding: 21px;

    p {

      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #414141;
    }
  }
}
