.auth-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  > h3 {
    text-align: center;
  }
}
