.controlBar {
  padding: 16px 36px;
  background: $white;
  box-shadow: inset 0px 1px 0px $light, inset 0px -1px 0px $light;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .filterLinks {
    display: flex;
    align-items: center;
    position: relative;

    li {
      margin-right: 8px;

      .cta {
        @include Montserrat(500);
        font-size: $font14;
        line-height: 100%;
        color: $gray-900;
        text-decoration: none;
        padding: 6px 14px;
        border: 1px solid $border;
        @include border-radius(32px);
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        i {
          margin-left: 8px;
        }

        &.show {
          &:after {
            content: '';
            background: url(/assets/images/pointerArrow.svg);
            background-repeat: no-repeat;
            width: 15px;
            height: 12px;
            position: absolute;
            bottom: -8px;
            left: 15px;
            z-index: 9999;
          }
        }

        + {
          .dropdownMenu {
            position: absolute;
            top: 100%;
            min-width: 402px;
            width: 100%;
            background: #ffffff;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            z-index: 999;
            padding: 24px;
            margin-top: 13px;
            border: none;

            .popup-icon {
              position: absolute;
              top: -9px;
            }

            .relative {
              position: relative;

              .form-control {
                border: 1px solid #eaecee;
                border-radius: 32px;
                height: 35px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;

                &::placeholder {
                  color: #d6dade;
                }
              }

              i {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }
  }

  .searchField {
    min-width: 239px;
    position: relative;

    .form-control {
      border: 1px solid $border;
      @include border-radius(32px);
      @include Montserrat(500);
      font-size: 14px;
      height: 40px;
      padding-right: 40px;

      &::placeholder {
        color: #d6dade;
      }

      &:focus {
        box-shadow: none;
      }
    }

    i {
      right: 15px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.overviewLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  li {
    padding: 0 6px;

    a {
      text-decoration: none;
      font-size: 14px;
      line-height: 100%;
      color: #0c1116;
      border: 1px solid #eaecee;
      border-radius: 32px;
      height: 40px;
      padding: 13px 20px;

      &.btn {
        i {
          margin-left: 8px;
        }
      }
    }
  }
}

.visitor-overview {
  padding: 16px 24px;
  background: #ffffff;
  border: 2px solid #efeeeb;
  border-radius: 12px;
  margin-bottom: 16px;

  p {
    margin: 0 0 6px;
    font-weight: 400;
    font-size: 12.2195px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #606060;
  }

  .counter {
    font-weight: 700;
    font-size: 22.4024px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #07364b;
  }
}

.cardTraffice {
  padding: 16px 24px;
  background: #ffffff;
  border: 2px solid #efeeeb;
  border-radius: 12px;

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 16px;
  }

  .media {
    display: flex;
    align-items: center;
    padding: 8px 0;

    .icon {
      flex: 0 0 100px;
      display: flex;
      align-items: center;

      i {
        margin-right: 7px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-right: 8px;
    }

    .media-body {
      flex: 1;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: #000000;
      padding: 0 15px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .number {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #07364b;
    }
  }
}

.viewCard {
  background: url(/assets/images/bg-video.png);
  background-color: rgba(255, 255, 255, 0.9);
  background-position: bottom center;
  border: 2px solid #efeeeb;
  border-radius: 12px 12px;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-size: cover;

  .ratio {
    flex: 1;
  }

  .cardFooter {
    padding: 10px 16px;
    background-color: rgba(255, 255, 255, 0.9);

    .row {
      [class*='col-'] {
        margin-bottom: 0;
      }
    }

    span {
      font-weight: 400;
      font-size: 12.2195px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: #606060;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    h4 {
      font-weight: 700;
      font-size: 22.4024px;
      line-height: 27px;
      letter-spacing: 0.01em;
      color: #07364b;
    }
  }
}

@include media-breakpoint-down(xl) {
  .controlBar {
    padding: 16px;
  }
}

@include media-breakpoint-down(md) {
  .controlBar .filterLinks.ms-auto {
    width: 100%;
    margin-top: 15px;
  }

  .profileCard .profileInfo {
    margin-bottom: 16px;
  }

  .overviewLinks li a {
    padding: 8px;
  }
}

@include media-breakpoint-down(sm) {
  .controlBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .controlBar .filterLinks {
    width: 100%;
  }

  .controlBar .searchField {
    min-width: 100%;
    margin-top: 10px;
  }

  .overviewLinks {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    li {
      padding: 5px;

      &:last-child {
        display: none;
      }
    }
  }

  .cardTraffice {
    padding: 16px;
  }

  .cardTraffice .media .media-body {
    padding: 0 6px;
  }

  .cardTraffice .media .icon i {
    margin-right: 0;
  }

  .cardTraffice .media .icon span {
    display: none;
  }

  .cardTraffice .media .icon {
    flex: 0 0 30px;
  }

  .overviewLinks li a {
    font-size: 12px;
  }

  .controlBar .filterLinks li.form .cta + .dropdownMenu {
    min-width: 300px;
    transform: translate(-50%, 0) !important;
    left: 50% !important;
    top: 100% !important;
  }

  .controlBar .filterLinks li .cta + .dropdownMenu {
    min-width: 300px;
  }
}
