.sign-in_button {
    font-family: "NunitoSans", sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000000;

    @media (max-width: 1100px) {
        font-family: "NunitoSans-Regular", sans-serif;;
        font-size: 16px;
        line-height: 22px;
        color: #1A1A1A;
    }

    &:hover{
        color: #FFDB11;
    }
}
