@import "../../mixins.scss";

.modal_cover_section_of_video {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.6);
    display: flex;

    .video_modal_section {
        width: 85%;
        margin: 0 auto;

        .video_of_modal_section {
            width: 106rem;
            min-width: 700px;
            position: fixed;
            background: transparent;
            margin: 0 auto;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include ipad {
                min-width: 600px;
            }
            @include phone {
                min-width: unset;
                max-height: 240px;
            }

            .image_modal {
                height: 100%;
                width: 100%;
            }

            @include phone {
                width: 95%;
            }
            .video_grabber {
                position: relative;
                max-width: 100%;
                overflow: hidden;
                padding-top: 56%;

                .video_of_watch_preview {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100% !important;
                    height: 100% !important;
                    border: none;
                }
            }

            .close_section {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: center;
                top: -21px;
                z-index: 4;
                @include phone {
                    top: -50px;
                }
            }
        }
    }
}

.hide_modal {
    display: none;
}
