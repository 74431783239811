.tourMain {
  [class*='col-'] {
    margin-top: 8px;
  }

  margin-bottom: 24px;
}

.badgeRed {
  background: $danger;
  color: $white;
}

.badgeWarning {
  background: $warning;
  color: $white;
}

.badgeDarkWarning {
  background: $darkWarning;
  color: $white;
}

.badgeOutlineDanger,
.badges-pending {
  border: 1px solid $danger;
  color: $danger;
  background: white;
}

.badgeDanger,
.badges-requested {
  color: $white;
  background: $danger;
}

.badgeDefault {
  background: $light;
  color: $gray-800;
}

.badgeSuccess {
  background: $success;
  color: $white;
}

.pricingMain {
  label {
    font-size: 14px;

    span {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }
  }

  .sizeList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -12px;

    li {
      padding: 4px 12px;
    }
  }
}

.packages {
  margin-bottom: 24px;

  .serviceCharges {
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 150%;
      color: $gray-700;
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -6px;
      gap: 2px;

      li {
        padding: 0 6px;

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 12px;
          line-height: 100%;
          font-weight: 500;
          background: $primary;
          border-radius: 12px;
          padding: 5px 10px;
        }
      }
    }
  }
}

.mediaBody {
  .name {
    color: #242d35 !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
}
