.sign-in-dropdown-wrapper {
  width: 182px;
  height: 72px;
  box-shadow: 0px 4px 30px rgba(26, 26, 26, 0.25);
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: -46px;

    @media(max-width: 13700px) {
        right: -14px;
    }

    @media (max-width: 1100px) {
        top: 1px;
        right: -53px;
    }

  .sign-in-btn-wrapper {
      margin: 0 0 13px 10px;

      @media (max-width: 1100px) {
          margin: auto 0 auto 10px;
      }

      &:hover {
          color: #FFDB11 !important;
      }
  }

  .account-info {
      display: flex;
      width: 182px;
      height: 28px;
      padding-left: 10px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
      margin-top: 8px;

    .my-account-info,
    .create-new-account {
      font-family: "NunitoSans-Bold", sans-serif;
      font-style: normal;
      font-size: 13px;
      line-height: 18px;
      cursor: pointer;

        @media (max-width: 1100px) {
            font-family: "NunitoSans-Regular", sans-serif;
            font-size: 16px;
            line-height: 22px;
            color: #1A1A1A;
        }
    }

    .create-new-account {
      color: #1A1A1A;
    }

    .my-account-info {
      color: #1a1a1a;
    }

      @media (max-width: 1100px) {
          width: 100vw;
      }
  }

    @media (max-width: 1100px) {
        width: 100vw;
        right: -108px;
        border-radius: 0;
    }

    @media (max-width: 1100px) {
        width: 100vw;
        right: -100px;
    }
}
